// Upload functions & constants
import { getVideoHTMLElement } from './utils'

export const MIME_TYPES = {
	image: {
		'.jpeg': 'image/jpeg',
		'.jpg': 'image/jpeg',
		'.png': 'image/png',
		'.gif': 'image/gif',
		'.tiff': 'image/tiff',
		'.svg': 'image/svg+xml',
		'.webp': 'image/webp',
	},
	video: {
		'.mpeg': 'video/mpeg',
		'.avi': 'video/x-msvideo', // For safari
		'.avi2': 'video/avi', // For chrome
		'.mov': 'video/quicktime',
		'.mp4': 'video/mp4',
		'.webm': 'video/webm',
		'.wmv': 'video/x-ms-wmv',
	},
	other: {
		'.txt': 'text/plain',
		'.pdf': 'application/pdf',
		'.csv': 'text/csv',
		'.doc': 'application/msword',
		'.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		'.xls': 'application/vnd.ms-excel',
		'.xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		'.ppt': 'application/vnd.ms-powerpoint',
		'.pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
		'.pages': 'application/x-iwork-pages-sffpages',
		'.ics': 'text/calendar',
	},
}

export const DEFAULT_MIME_TYPES = Object.values(MIME_TYPES).flatMap((categories) => Object.values(categories))
const MAX_FILE_SIZE = 300 // 300 MB maximum
const MAX_VIDEO_DURATION = 600 // 10 mins maximum
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE * 10 ** 6

export class FileValidationError extends Error {
	constructor(message, file) {
		super(message)
		this.name = 'FileValidationError'
		this.file = file
	}
}

// Throws error if incorrect file
export async function isFileOk(file, acceptedMimeTypes = DEFAULT_MIME_TYPES) {
	if (!file) {
		throw new FileValidationError(`Could not find file.`, file)
	}

	const isOk = acceptedMimeTypes.includes(file.type)
	if (!isOk) {
		throw new FileValidationError(`The file has an invalid format - ${file.type}`, file)
	}

	if (file.size > MAX_FILE_SIZE_BYTES) {
		throw new FileValidationError(`The file is too large (> ${MAX_FILE_SIZE} MB)`, file)
	}

	if (file.type.split('/')[0] === 'video') {
		const supported = !!document.createElement('video').canPlayType(file.type)
		if (supported) {
			const { duration } = await getVideoHTMLElement(URL.createObjectURL(file))
			if (duration > MAX_VIDEO_DURATION) {
				throw new FileValidationError(`The video duration is too long (> 10 minutes)`, file)
			}
		}
	}

	return true
}
