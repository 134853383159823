import { TrashIcon } from '@heroicons/react/solid';
import { Box, TextField, Tooltip, Typography } from '@mui/material';
import { SnackeetCardArea } from 'components/styled/SnackeetCard';
import { BrandButton } from 'containers/BrandEditor/BrandEditor.style';
import { FieldWrapper } from 'containers/Editor/StoryEditor/Preview/Interactions/FormInteraction.style';
import { DEFAULT_FONT_FAMILIES } from 'lib/constants';
import { getFontName } from 'lib/utils';
import _ from 'lodash';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getFonts } from 'redux/fonts/selectors';
import { BrandCardContainer, BrandCardInfo, ColorCircle, LogoPreset, TextSample } from './BrandCard.style';
import DeleteBrandModal from './DeleteBrandModal';
const formFieldInputProps = {
  readOnly: true,
  disabled: true
};
export default function BrandCard({
  brand,
  index,
  currentSlug,
  deleteBrand,
  isSmall = false
}) {
  const intl = useIntl();
  const customFonts = useSelector(getFonts);
  const customBrandFont = _.find(customFonts, {
    font_id: brand.fontFamily
  });
  const isDeletedBrandFontFamily = !customBrandFont && !DEFAULT_FONT_FAMILIES.includes(brand.fontFamily);
  function renderCard() {
    if (isSmall) {
      return <div className='brand-small'>
					<LogoPreset>
						{brand.logo ? <img src={brand.logo} /> : <Box className='no-logo'>No Logo</Box>}
					</LogoPreset>

					<div className='brand-colors-font'>
						<Typography variant='body2'>
							<b>{intl.messages['common.colors']}</b>
						</Typography>
						<Colors colors={brand.colors} />
					</div>
					<div className='brand-font-family'>
						<Typography variant='body2'>
							<b>{intl.messages['common.fontFamily']}</b>
						</Typography>
						{isDeletedBrandFontFamily ? <Typography variant='body2' color='textSecondary'>
								{intl.messages['common.unset']}
							</Typography> : <TextSample $fontFamily={brand.fontFamily}>
								{customBrandFont ? getFontName(customBrandFont) : brand.fontFamily}
							</TextSample>}
					</div>

					<div className='brand-buttons'>
						<BrandButton style={brand.theming.buttons}>{intl.messages['common.buttons']}</BrandButton>
					</div>
				</div>;
    }
    return <div className='brand-items'>
				<LogoPreset>
					{brand.logo ? <img src={brand.logo} /> : <Box className='no-logo'>No Logo</Box>}
				</LogoPreset>

				<div className='brand-colors'>
					<Typography variant='body2'>
						<b>{intl.messages['common.colors']}</b>
					</Typography>
					<Colors colors={brand.colors} />
				</div>

				<div className='brand-fields'>
					<FieldWrapper $fieldStyle={convertFieldstyle(brand.theming.fields)}>
						<Typography style={convertFieldstyle(brand.theming.labels)}>
							{intl.messages['common.field_label']}
						</Typography>
						<TextField fullWidth variant='outlined' placeholder={intl.messages['edition_menu.form.field.default_placeholder.text']} type='text' inputProps={formFieldInputProps} />
					</FieldWrapper>
				</div>

				<div className='brand-font-family'>
					<Typography variant='body2'>
						<b>{intl.messages['common.fontFamily']}</b>
					</Typography>
					{isDeletedBrandFontFamily ? <Typography variant='body2' color='textSecondary'>
							{intl.messages['common.unset']}
						</Typography> : <TextSample $fontFamily={brand.fontFamily}>
							{customBrandFont ? getFontName(customBrandFont) : brand.fontFamily}
						</TextSample>}
				</div>

				<div className='brand-buttons'>
					<BrandButton style={brand.theming.buttons}>{intl.messages['common.buttons']}</BrandButton>
				</div>
			</div>;
  }
  if (currentSlug) {
    return <SnackeetCardArea index={index}>
				<BrandCardContainer $isSmall={isSmall}>
					<BrandCardInfo>
						<Link href={`/${currentSlug}/brand/${brand._id}`} passHref legacyBehavior>
							<Typography className='brand-name'>{brand.name}</Typography>
						</Link>

						<div className='tools'>
							{deleteBrand && <DeleteBrandModal trigger={<Tooltip title={intl.messages['common.delete']} placement='bottom'>
											<div>
												<TrashIcon fontSize='small' />
											</div>
										</Tooltip>} brand={brand} deleteBrand={deleteBrand} />}
						</div>
					</BrandCardInfo>
					<Link href={`/${currentSlug}/brand/${brand._id}`} passHref legacyBehavior>
						{renderCard()}
					</Link>
				</BrandCardContainer>
			</SnackeetCardArea>;
  }
  return <SnackeetCardArea index={index}>
			<BrandCardContainer $isSmall={isSmall}>
				<BrandCardInfo>
					<Typography className='brand-name'>{brand.name}</Typography>

					<div className='tools'>
						{deleteBrand && <DeleteBrandModal trigger={<Tooltip title={intl.messages['common.delete']} placement='bottom'>
										<div>
											<TrashIcon fontSize='small' />
										</div>
									</Tooltip>} brand={brand} deleteBrand={deleteBrand} />}
					</div>
				</BrandCardInfo>
				{renderCard()}
			</BrandCardContainer>
		</SnackeetCardArea>;
}
BrandCard.propTypes = {
  brand: PropTypes.object,
  index: PropTypes.number,
  currentSlug: PropTypes.string,
  deleteBrand: PropTypes.func,
  isSmall: PropTypes.bool
};

// Functional components
function Colors({
  colors
}) {
  const intl = useIntl();
  if (_.isEmpty(colors)) {
    return <Typography variant='h5' color='textSecondary'>
				{intl.messages['common.unset']}
			</Typography>;
  }
  return <Box width='120px' position='relative' height='50px'>
			{_.map(colors, (color, index) => <Tooltip key={index} placement='bottom' title={color}>
					<ColorCircle key={index} $color={color} $left={getPosition(colors) * index} />
				</Tooltip>)}
		</Box>;
}

// Helper Functions
function getPosition(colors) {
  const diff = (120 - 40) / colors.length;
  if (diff >= 40) {
    return 40;
  } else {
    return diff;
  }
}
function convertFieldstyle(fieldstyle) {
  const result = _.cloneDeep(fieldstyle);
  switch (fieldstyle?.justifyContent) {
    case 'center':
      result.textAlign = 'center';
      break;
    case 'flex-end':
      result.textAlign = 'right';
      break;
    case 'flex-start':
    default:
      result.textAlign = 'left';
      break;
  }
  return result;
}