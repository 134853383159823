import { CodeIcon } from '@heroicons/react/outline';
import { ContentCopyOutlined, Delete, DragIndicator, ErrorOutlineOutlined, ImageOutlined, LinkOutlined,
// Content icons
TitleOutlined, ViewHeadlineOutlined } from '@mui/icons-material';
import { Box, IconButton, SvgIcon, TextField, Tooltip, Typography } from '@mui/material';
import { ColorInput, NumberInput } from 'atoms';
import { ConfirmationModal } from 'components/Modals';
import { FieldSettingsContainer } from 'components/styled/Modals';
import { getMediaDimensions, isUrl } from 'lib/utils';
import _ from 'lodash';
import { forwardRef, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { ElementImageImport } from '../../containers/Editor/StoryEditor/MediaAssets/ImageImport';
const CONTENT_ICONS_LIST = {
  title: <TitleOutlined />,
  paragraph: <ViewHeadlineOutlined />,
  disclamer: <ErrorOutlineOutlined />,
  image: <ImageOutlined />,
  button: <LinkOutlined />,
  iframe: <CodeIcon />
};
const ContentElementSettings = forwardRef(function ContentElementSettings({
  intl,
  element,
  canDelete,
  handleRemoveContentElement,
  handleDuplicateElement,
  handleChangeElementProperty,
  handleChangeElementStyle,
  isDragging,
  recentColors,
  brandColors,
  updateRecentColor,
  recentFonts,
  brandFontFamily,
  onRecentColorsUpdate,
  theme,
  ...delegatedProps
}, ref) {
  const confirmRef = useRef();
  async function handleChangeImage(value) {
    const {
      height,
      width
    } = await getMediaDimensions({
      type: 'image',
      url: value
    });
    handleChangeElementProperty(element._id, 'value', value);
    if (height) {
      handleChangeElementProperty(element._id, 'height', height);
    }
    if (width) {
      handleChangeElementProperty(element._id, 'width', width);
    }
  }
  function handleUpdateColor(path, value) {
    handleChangeElementStyle(element._id, path, value);
    updateRecentColor(value);
  }
  function onRemoveField() {
    handleRemoveContentElement(element._id);
    confirmRef.current.close();
  }
  function renderInputField(type, sub_type) {
    switch (element.sub_type) {
      case 'image':
        return <div className='consent-container'>
						<Tooltip placement='bottom' title={intl.messages[`edition_menu.form.field_type.${sub_type}`]}>
							<div className='sub-type-icon'>
								<SvgIcon>{CONTENT_ICONS_LIST[sub_type]}</SvgIcon>
							</div>
						</Tooltip>
						<Box width='400px' display='grid' gridTemplateColumns='1fr auto' alignItems='center'>
							<ElementImageImport handleAnswerUpdate={value => handleChangeImage(value)} url={element.value} />
							{_.isEmpty(element?.value) && <Box pl={2}>
									<Typography variant='body2' color='error'>
										{intl.messages['edition_menu.answer.image_url.error']}
									</Typography>
								</Box>}
						</Box>
					</div>;
      case 'button':
        return <div className='placeholder-container'>
						<Tooltip placement='bottom' title={intl.messages[`edition_menu.form.field_type.${sub_type}`]}>
							<div className='sub-type-icon'>
								<SvgIcon>{CONTENT_ICONS_LIST[sub_type]}</SvgIcon>
							</div>
						</Tooltip>
						<TextField fullWidth variant='outlined' value={element.value} onChange={evt => handleChangeElementProperty(element._id, 'value', evt.target.value)} />
					</div>;
      case 'iframe':
        return <div className='placeholder-container'>
						<Tooltip placement='bottom' title={intl.messages[`edition_menu.form.field_type.${sub_type}`]}>
							<div className='sub-type-icon'>
								<SvgIcon>{CONTENT_ICONS_LIST[sub_type]}</SvgIcon>
							</div>
						</Tooltip>
						<TextField fullWidth variant='outlined' placeholder={intl.messages['edition_menu.form.label.placeholder']} value={element.value || ''} onChange={evt => handleChangeElementProperty(element._id, 'value', evt.target.value)} error={!isUrl(element.value)?.success} />
					</div>;
      default:
        return <div className='consent-container'>
						<Tooltip placement='bottom' title={intl.messages[`edition_menu.form.field_type.${sub_type}`]}>
							<div className='sub-type-icon'>
								<SvgIcon>{CONTENT_ICONS_LIST[sub_type]}</SvgIcon>
							</div>
						</Tooltip>
						<TextField fullWidth variant='outlined' value={element.value} multiline minRows={2} onChange={evt => handleChangeElementProperty(element._id, 'value', evt.target.value)} />
					</div>;
    }
  }
  function renderAdvanceSettings(sub_type) {
    switch (sub_type) {
      case 'title':
      case 'paragraph':
        return <Box width='100%'>
						<div className='properties-wrapper'>
							<div className='property-line'>
								<Typography variant='body1' component='label' className='property-field'>
									<FormattedMessage id='common.size' />
								</Typography>
								<div className='property-content'>
									<NumberInput initial_value={getFontSize(element)} onUpdate={value => handleChangeElementStyle(element._id, 'fontSize', `${value}px`)} maxValue={100} minValue={6} units='px' inputStep={1} presetValues={[6, 8, 10, 12, 14, 16, 18, 21, 24, 26, 28, 32, 36, 42, 48, 56, 64, 72, 80, 96]} />
								</div>
							</div>
							<ColorInput value={getColor(element, theme)} updateValue={value => handleUpdateColor('color', value)} recentColors={recentColors} brandColors={brandColors} updateRecentColors={onRecentColorsUpdate} />
						</div>
					</Box>;
      case 'button':
        return <Box width='100%'>
						<Box width='100%' display='flex' alignItems='center'>
							<Typography style={{
              marginRight: '4px'
            }}>
								<b>URL</b>
							</Typography>
							<TextField fullWidth variant='outlined' placeholder={intl.messages['edition_menu.form.label.placeholder']} value={element.url || ''} onChange={evt => handleChangeElementProperty(element._id, 'url', evt.target.value)} error={!isUrl(element.url)?.success} />
						</Box>
						{!isUrl(element.url)?.success && <Box ml={4}>
								<Typography variant='body2' color='error'>
									{intl.messages['edition_menu.attachment.outlink_link_error']}
								</Typography>
							</Box>}

						<div className='properties-wrapper'>
							<div className='property-line'>
								<Typography variant='body1' component='label' className='property-field'>
									<FormattedMessage id='common.size' />
								</Typography>
								<div className='property-content'>
									<NumberInput initial_value={parseInt(element?.styles?.fontSize)} onUpdate={value => handleChangeElementStyle(element._id, 'fontSize', `${value}px`)} maxValue={450} minValue={6} units='px' inputStep={1} presetValues={[6, 8, 10, 12, 14, 16, 18, 21, 24, 26, 28, 32, 36, 42, 48, 56, 64, 72, 80, 96]} />
								</div>
							</div>
							<div className='property-line'>
								<Typography variant='body1' component='label' className='property-field'>
									<FormattedMessage id='common.radius' />
								</Typography>
								<div className='property-content'>
									<NumberInput initial_value={parseInt(element?.styles?.borderRadius || '0px')} onUpdate={value => handleChangeElementStyle(element._id, 'borderRadius', `${value}px`)} maxValue={100} units='px' inputStep={1} presetValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]} />
								</div>
							</div>
						</div>
						<div className='properties-wrapper'>
							<ColorInput value={element?.styles.color} updateValue={value => handleUpdateColor('color', value)} recentColors={recentColors} brandColors={brandColors} updateRecentColors={onRecentColorsUpdate} />
							<ColorInput value={element?.styles.backgroundColor} updateValue={value => handleUpdateColor('backgroundColor', value)} label={intl.messages['common.background_color']} recentColors={recentColors} brandColors={brandColors} updateRecentColors={onRecentColorsUpdate} />
						</div>
					</Box>;
      case 'iframe':
        return <Box width='100%'>
						{!isUrl(element.value)?.success && <Box ml={4}>
								<Typography variant='body2' color='error'>
									{intl.messages['edition_menu.attachment.outlink_link_error']}
								</Typography>
							</Box>}
						<div className='properties-wrapper'>
							<div className='property-line'>
								<Typography variant='body1' component='label' className='property-field'>
									<FormattedMessage id='common.height' />
								</Typography>
								<div className='property-content'>
									<NumberInput initial_value={parseInt(element?.height)} onUpdate={value => handleChangeElementProperty(element._id, 'height', `${value}px`)} maxValue={1000} minValue={0} units='px' inputStep={5} presetValues={[100, 200, 300, 350, 400, 500, 550, 600, 700]} />
								</div>
							</div>
							<div className='property-line'>
								<Typography variant='body1' component='label' className='property-field'>
									<FormattedMessage id='common.radius' />
								</Typography>
								<div className='property-content'>
									<NumberInput initial_value={parseInt(element?.styles?.borderRadius || '0px')} onUpdate={value => handleChangeElementStyle(element._id, 'borderRadius', `${value}px`)} maxValue={100} units='px' inputStep={1} presetValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]} />
								</div>
							</div>
						</div>
					</Box>;
      default:
        return <div />;
    }
  }
  return <>
			<FieldSettingsContainer ref={ref} $isDragging={isDragging} {...delegatedProps}>
				<div className='drag-handle'>
					<DragIndicator />
				</div>

				<Box width='100%'>
					{renderInputField(element.type, element.sub_type)}

					<Box mt={1}>{renderAdvanceSettings(element.sub_type)}</Box>
				</Box>

				<Box display='flex' flexDirection='column' gap={2}>
					<Tooltip title={canDelete ? intl.messages['button.delete'] : intl.messages['edition_menu.form.field.delete_error']} placement='bottom'>
						<div>
							<IconButton size='small' disabled={!canDelete} onClick={() => confirmRef.current.open()}>
								<Delete />
							</IconButton>
						</div>
					</Tooltip>
					<Tooltip title={intl.messages['button.duplicate']} placement='bottom'>
						<IconButton size='small' onClick={() => handleDuplicateElement(element)}>
							<ContentCopyOutlined />
						</IconButton>
					</Tooltip>
				</Box>
			</FieldSettingsContainer>

			<ConfirmationModal size='sm' confirmationModalRef={confirmRef} title={<FormattedMessage id='modals.form.field_remove.title' />} onConfirm={onRemoveField} firstLine={<FormattedMessage id='modals.form.field_remove.sub_title' />} secondLine={<FormattedMessage id='modal.file_deletion.confirmation.instructions' />} confirmText={<FormattedMessage id='common.yes' />} cancelText={<FormattedMessage id='common.no' />} />
		</>;
});
ContentElementSettings.displayName = 'ContentElementSettings';
export default ContentElementSettings;

// Helper functions to refactor and init value

function getFontSize(element) {
  if (element.styles.fontSize) {
    return parseInt(element.styles.fontSize);
  } else if (element.sub_type === 'title') {
    return 28;
  } else if (element.sub_type === 'paragraph') {
    return 16;
  }
}
function getColor(element, theme) {
  if (element.styles.color) {
    return element.styles.color;
  } else if (theme === 'light') {
    return 'rgba(0, 0, 0, 100)';
  } else if (theme === 'dark') {
    return 'rgba(255, 255, 255, 100)';
  }
}