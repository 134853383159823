import { PuzzleIcon } from '@heroicons/react/outline';
import { Edit } from '@mui/icons-material';
import { Box, Chip, SvgIcon, Tooltip, Typography } from '@mui/material';
import { MediaReframe } from 'atoms';
import { ImagePreview } from 'components/atoms/AssetsPreview/MediaPreview';
import { ImagesSelectorModal } from 'components/Modals';
import ShapeSelector from 'components/ShapeSelector';
import { MediaButton, MediaButtonsContainer, ShapeWrapper } from 'containers/Editor/StoryEditor/Properties/Properties.style';
import { useStoryDispatch, useStoryState, useStoryStoreApi } from 'lib/editorStore';
import useProjectRecentlyUsed from 'lib/hooks/useProjectRecentlyUsed';
import _ from 'lodash';
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
export function BlockImageImport() {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'popover-shape-block'
  });
  const selectedElement = useStoryState(s => s.selectedElement);
  const storyStoreApi = useStoryStoreApi();
  const {
    updateRecentlyUsed
  } = useProjectRecentlyUsed();
  const dispatch = useStoryDispatch();
  const onSelect = useCallback(async ({
    url,
    file_id,
    name,
    instagramId
  }) => {
    // file id
    const fileIdOperation = {
      _id: selectedElement._id,
      path: 'file_id'
    };
    if (file_id) {
      fileIdOperation.value = file_id;
    } else {
      fileIdOperation.isSet = false;
    }
    // file original name
    const fileNameOperation = {
      _id: selectedElement._id,
      path: 'name'
    };
    if (name) {
      fileNameOperation.value = name;
    } else {
      fileNameOperation.isSet = false;
    }
    // instagramId
    const instagramIdOperation = {
      _id: selectedElement._id,
      path: 'instagramId'
    };
    if (file_id) {
      instagramIdOperation.value = instagramId;
    } else {
      instagramIdOperation.isSet = false;
    }
    const updateOperations = [{
      _id: selectedElement._id,
      path: 'value',
      value: url
    }, fileIdOperation, fileNameOperation, instagramIdOperation];
    await updateRecentlyUsed('images', {
      url,
      file_id,
      name
    });
    dispatch({
      type: 'UPDATE_BLOCK',
      data: updateOperations
    });
  }, [dispatch, selectedElement._id, updateRecentlyUsed]);
  const onReframe = useCallback(({
    crop,
    zoom,
    croppedArea
  }) => {
    // Only update block if no moveable transformation is taking place
    const isMoving = storyStoreApi.getState().isMoving;
    if (isMoving) return;
    const updateOperations = [];
    if (!_.isEqual(selectedElement.payload?.inner_style?.crop, crop)) {
      updateOperations.push({
        _id: selectedElement._id,
        path: 'inner_style.crop',
        value: crop
      });
    }
    if (!_.isEqual(selectedElement.payload?.inner_style?.zoom, zoom)) {
      updateOperations.push({
        _id: selectedElement._id,
        path: 'inner_style.zoom',
        value: zoom
      });
    }
    if (!_.isEqual(selectedElement.payload?.inner_style?.croppedArea, croppedArea)) {
      updateOperations.push({
        _id: selectedElement._id,
        path: 'inner_style.croppedArea',
        value: croppedArea
      });
    }
    if (!_.isEmpty(updateOperations)) {
      dispatch({
        type: 'UPDATE_BLOCK',
        data: updateOperations
      });
    }
  }, [dispatch, selectedElement._id, storyStoreApi]);
  const onUploadSuccess = useCallback(uploadedFiles => {
    if (uploadedFiles.length === 1) {
      const {
        _id: file_id,
        url,
        name,
        instagramId
      } = uploadedFiles[0];
      onSelect({
        url,
        file_id,
        name,
        instagramId
      });
    }
  }, [onSelect]);
  if (!selectedElement.payload?.value) {
    return null;
  }
  return <>
			<Box position='relative' width='100%' height='265px'>
				<MediaReframe url={selectedElement.payload?.value} blockWidth={parseFloat(selectedElement.payload.outer_style.width)} blockHeight={parseFloat(selectedElement.payload.outer_style.height)} filename={selectedElement.payload?.name} crop={selectedElement.payload.inner_style?.crop} zoom={selectedElement.payload.inner_style?.zoom} onReframe={onReframe} />
			</Box>

			<MediaButtonsContainer>
				<ImagesSelectorModal trigger={<Tooltip title={<FormattedMessage id='common.change_image' />} placement='bottom'>
							<MediaButton>
								<Edit />
								<Typography>
									<FormattedMessage id='common.change_image' />
								</Typography>
							</MediaButton>
						</Tooltip>} inputValue={selectedElement.payload?.value} onSelect={onSelect} onUploadSuccess={onUploadSuccess} />

				<MediaButton {...bindTrigger(popupState)}>
					<SvgIcon>
						<PuzzleIcon />
					</SvgIcon>
					<Typography>
						<FormattedMessage id='edition_menu.media.frame.title' />
					</Typography>
					<Chip style={{
          position: 'absolute',
          top: '4px',
          right: '4px'
        }} label='New' color='primary' size='small' />
				</MediaButton>

				<ShapeWrapper {...bindPopover(popupState)} anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }} transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}>
					<ShapeSelector onShapeAdded={() => popupState.close()} isWidgetBar={true} isBlockFrame={true} />
				</ShapeWrapper>
			</MediaButtonsContainer>
		</>;
}
export function ElementImageImport({
  url,
  handleAnswerUpdate
}) {
  const onSelect = useCallback(({
    url
  }) => {
    handleAnswerUpdate(url);
  }, [handleAnswerUpdate]);
  const onUploadSuccess = useCallback(uploadedFiles => {
    if (uploadedFiles.length === 1) {
      const {
        _id: file_id,
        url,
        name,
        instagramId
      } = uploadedFiles[0];
      onSelect({
        url,
        file_id,
        name,
        instagramId
      });
    }
  }, [onSelect]);
  return <ImagesSelectorModal trigger={<ImagePreview inputValue={url} />} inputValue={url} onSelect={onSelect} onUploadSuccess={onUploadSuccess} />;
}
ElementImageImport.propTypes = {
  url: PropTypes.string,
  handleAnswerUpdate: PropTypes.func
};