import { css } from '@emotion/react'
import { Badge, Box, Typography } from '@mui/material'
import styled from '@styled'
import { darken } from 'polished'
import { snackeet_blue, snackeet_yellow } from 'styles/colors'

// ---------------* Index Page *-------------------

export const SettingsContainer = styled.div`
	display: grid;
	grid-template-columns: minmax(300px, auto) 1fr;
	grid-template-rows: 1fr;
	grid-column-gap: ${({ theme }) => theme.spacing(6)};
	padding: ${({ theme }) => theme.spacing(2)};
	width: 100%;
	height: 100%;
	overflow: hidden;
`

export const SettingsRightSection = styled(Box)`
	background-color: ${({ theme }) => theme.palette.background.paper};
	color: ${({ theme }) => theme.palette.text.primary};
	box-shadow: ${({ theme }) => theme.shadows[1]};

	border-radius: 10px;
	height: max-content;
	flex-shrink: 0;

	&:not(:last-of-type) {
		margin-bottom: ${({ theme }) => theme.spacing(2)};
	}
`
SettingsRightSection.defaultProps = {
	px: 3,
	py: 3,
	minWidth: 800,
	maxWidth: '80%',
	width: 'auto',
}

export const OrganizationSettingsRightSection = styled(Box)``
OrganizationSettingsRightSection.defaultProps = {
	display: 'flex',
	flexDirection: 'column',
	p: 4,
	minHeight: 400,
	width: '100%',
	maxWidth: 1200,
	overflow: 'auto',
	backgroundColor: 'background.paper',
	color: 'text.primary',
	boxShadow: 1,
	borderRadius: 2.5,
}

// ---------------* OptionList *-----------------

export const OptionList = styled.aside`
	width: ${({ $width }) => $width ?? 300}px;
	padding: 16px;
	padding-top: 50px;
	height: max-content;
	position: relative;

	@media (max-height: 700px) {
		padding: 16px 16px 8px;
		padding-top: 36px;
	}
`

const selectableOptionColor = 'rgba(221,233,255,0.59)'
const activeStyle = css`
	background-color: #2f3161;
	color: white;
	&:hover {
		background-color: #2f3161;
	}
`
const disabledStyle = ({ theme }) => css`
	cursor: inherit;
	background-color: ${theme.palette.action.disabledBackground};
	color: ${darken(0.5, theme.palette.text.secondary)};
	&:hover {
		background-color: ${theme.palette.action.disabledBackground};
	}
`
export const SelectableOption = styled.div`
	margin-bottom: 5px;
	display: flex;
	align-items: center;
	position: relative;
	cursor: pointer;
	height: 40px;
	width: 100%;
	padding: 0 12px;
	border-radius: 5px;
	font-size: 14px;
	position: relative;

	transition: background-color 200ms ease-in;
	background-color: ${selectableOptionColor};
	color: black;
	&:hover {
		background-color: ${darken(0.1, selectableOptionColor)};
	}

	${({ $isActive, $isDisabled, theme }) => ($isDisabled ? disabledStyle({ theme }) : $isActive ? activeStyle : '')}

	::after {
		content: '';
		background-color: ${({ theme }) => theme.palette.primary.main};
		position: absolute;
		width: 8px;
		border-radius: 4px;
		transition: height 0.2s ease-out;
		height: ${({ $isActive }) => ($isActive ? '100%' : '0')};
		left: 0;
		top: 0;
	}

	& > svg,
	img {
		margin: ${({ theme }) => theme.spacing(0, 1.5, 0, 0.5)};
		height: 20px;
		width: 20px;
	}

	@media (max-height: 700px) {
		font-size: 10px;
		height: 30px;
	}
`

export const ListTitle = styled.div`
	background-color: ${({ theme }) => theme.palette.primary.light};
	position: absolute;
	z-index: 1;
	left: 0;
	top: 0;
	color: ${({ theme }) => theme.palette.text.primary};
	display: inline-flex;
	border-radius: 5px 5px;
	width: 100%;
	height: 40px;
	/* font-family: 'Poppins', sans-serif; */
	font-weight: 600;
	font-size: 15px;
	padding: 10px 30px;
	align-items: center;

	@media (max-height: 700px) {
		font-size: 11px;
		height: 30px;
		padding: 10px 20px;
	}
`

// ---------------* Settings Page *-----------------

export const SettingTitle = styled.div`
	display: flex;
	font-size: 22px;
	font-weight: bold;
	margin-bottom: ${({ theme }) => theme.spacing(2)};
`

export const ProfileSectionContainer = styled.div`
	max-width: 800px;
	padding: ${({ theme }) => theme.spacing(1, 3)};
	margin-bottom: ${({ theme }) => theme.spacing(2)};
	width: 100%;
	display: flex;
	align-items: center;

	.logo {
		width: 25%;
		text-align: center;
		margin: ${({ theme }) => theme.spacing(0, 2)};

		img {
			height: 150px;
			width: 150px;
			object-fit: contain;
			border: 1px solid #ccc;
			border-radius: 16px;
			padding: ${({ theme }) => theme.spacing(0.5)};
		}
	}
`

export const SettingsGrid = styled.div`
	display: grid;
	grid-template-columns: minmax(max-content, 200px) 1fr;
	grid-template-rows: repeat(auto-fit, minmax(36px, 1fr));
	grid-gap: ${({ theme }) => theme.spacing(2)};
	align-items: center;
	width: 100%;

	& > div:nth-child(2n + 1) {
		font-weight: bold;
	}

	.notification-lang {
		.MuiInputBase-root {
			font-size: 20px;
		}
	}
`

export const LanguageLine = styled.div`
	width: 280px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: flex-start;

	.label {
		min-width: 140px;
		margin-right: ${({ theme }) => theme.spacing(2)};
	}
`

// Notifications à mettre en dessous

export const StorySettingsContainer = styled.div`
	height: fit-content;
	padding: ${({ theme }) => theme.spacing(2)};
`

export const AmpStorySettingsContainer = styled.div`
	height: fit-content;
	padding: ${({ theme }) => theme.spacing(1)};
	margin-top: ${({ theme }) => theme.spacing(1)};
	width: 100%;

	.MuiSelect-root {
		padding: 12px 8px;
		font-size: 14px;
	}
	.MuiOutlinedInput-input {
		padding: 12px 8px;
		font-size: 14px;
	}

	.parameter-title {
		margin-bottom: 4px;
	}

	.holder {
		width: 215px;
	}
	.preview {
		min-height: 164px;
		position: relative;
		border-radius: 5px;
		overflow: hidden;
		box-shadow: 0 1px 6px rgb(32 33 36 / 28%);

		img {
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.poster {
		height: 286px;

		.preview-top {
			position: absolute;
			top: 15px;
			left: 15px;

			img {
				position: absolute;
				top: 0px;
				left: 0px;
				width: 30px;
				height: 30px;
				object-fit: cover;
				border-radius: 3px;
				background: rgb(255, 255, 255);
			}
		}

		.preview-bottom {
			position: absolute;
			bottom: 0px;
			left: 0px;
			width: 100%;
			background: linear-gradient(0deg, rgba(33, 33, 33, 0.9), rgba(33, 33, 33, 0));
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			font-family: 'Inter';
			padding: 16px;

			p {
				font-weight: 400;
				line-height: 1;
				font-size: 15.4px;
				margin-bottom: 16px;
				margin-top: 0;
			}

			.headline {
				text-transform: uppercase;
				color: white;
			}
			.domain {
				color: hsla(0, 0%, 100%, 0.7);
			}
			.time-ago {
				color: hsla(0, 0%, 100%, 0.7);
			}
			.story-icon {
				position: absolute;
				right: 15px;
				bottom: 15px;
				font-size: 18px;
				color: white;
			}
		}
	}

	.posterLandscape {
		border-radius: 8px;

		.img-holder {
			width: 100%;
			height: 112px;
			position: relative;

			.amp-logo {
				position: absolute;
				top: 12px;
				right: 16px;
				height: 10px;
				width: 10px;
				background-size: 100% 100%;
				background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAQAAABLCVATAAABr0lEQVR4AbWWJYCUURhFD04Zi7hrLzgFd4nzV9x6wKHinmYb7g4zq71gIw2LWBnZ3Q8df/fh96Tn/t2HVIw4CVKk+fSFNCkSxInxW1pFkhLmoMRjVvFLmkEX5ocuZuBVPw5jv8hh+iEU5QEmuMK+prz7RN3dPMMEGQYzxpH/lGjzou5jgl7mAvOdZfcbF+jbm3MAbFZ7VX9SJnlL1D8UMyjLe+BrAYDb+jJUr59JrlNWRtcqX9GkrPCR4QBAf4qYJAkQoyQrbKKs8RiaEjEI0GvvQ1mLMC9xaBFFBaZS1TbMSwJSomg39erDF+TxpCCNOXjGQJTCvG6qn4ZPzkcxA61Tjhaf4KMj+6Q3XvW6Lopraa8IozRQxIi0a7NXorULc5JyHX/3F3q+0PsFYytVTaGgjz/AvCyiegE69IUsPxHNBMpa738i6tGWlzkAABjKe/+j9YeRHGVd9oWRnwe2ewDASp/L/UqoPQ5AmFeYZMavBP8dAJz0GWWDHQlzXApMdz4KYUfKICcxkKeOfGmQyrIPcgE9m+g/+kT812/Nr3+0kqzitxQjoKXh6xfor99nlEdFjyvH15gAAAAASUVORK5CYII=);
				filter: invert(1);
				z-index: 2;
			}
		}
		.preview-bottom {
			padding: 10px 16px;
			position: relative;
			min-height: 60px;
			background: white;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			font-family: 'Inter';

			p {
				font-weight: 400;
				line-height: 1;
				font-size: 15.4px;
				margin-bottom: 16px;
				margin-top: 0;
			}

			.headline {
				text-transform: uppercase;
				color: #1657cd;
				margin-bottom: 5px;
			}

			.time-ago {
				color: #70757a;
			}
		}
	}

	.posterSquare {
		.preview-top {
			height: 40px;
			padding: 0 16px;
			display: flex;
			align-items: center;

			.flavicon {
				position: relative;
				margin-right: 8px;
				width: 16px;
				height: 16px;
			}

			.domain {
				color: #3c4043;
				font-size: 12px;
			}

			.amp-logo {
				position: absolute;
				top: 15px;
				right: 16px;
				height: 10px;
				width: 10px;
				background-size: 100% 100%;
				background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAQAAABLCVATAAABr0lEQVR4AbWWJYCUURhFD04Zi7hrLzgFd4nzV9x6wKHinmYb7g4zq71gIw2LWBnZ3Q8df/fh96Tn/t2HVIw4CVKk+fSFNCkSxInxW1pFkhLmoMRjVvFLmkEX5ocuZuBVPw5jv8hh+iEU5QEmuMK+prz7RN3dPMMEGQYzxpH/lGjzou5jgl7mAvOdZfcbF+jbm3MAbFZ7VX9SJnlL1D8UMyjLe+BrAYDb+jJUr59JrlNWRtcqX9GkrPCR4QBAf4qYJAkQoyQrbKKs8RiaEjEI0GvvQ1mLMC9xaBFFBaZS1TbMSwJSomg39erDF+TxpCCNOXjGQJTCvG6qn4ZPzkcxA61Tjhaf4KMj+6Q3XvW6Lopraa8IozRQxIi0a7NXorULc5JyHX/3F3q+0PsFYytVTaGgjz/AvCyiegE69IUsPxHNBMpa738i6tGWlzkAABjKe/+j9YeRHGVd9oWRnwe2ewDASp/L/UqoPQ5AmFeYZMavBP8dAJz0GWWDHQlzXApMdz4KYUfKICcxkKeOfGmQyrIPcgE9m+g/+kT812/Nr3+0kqzitxQjoKXh6xfor99nlEdFjyvH15gAAAAASUVORK5CYII=);
				opacity: 0.5;
				z-index: 2;
			}
		}
		.img-holder {
			height: 215px;
			position: relative;
		}
		.preview-bottom {
			position: absolute;
			bottom: 0px;
			left: 0px;
			width: 100%;
			background: linear-gradient(0deg, rgba(33, 33, 33, 0.9), rgba(33, 33, 33, 0));
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			font-family: 'Inter';
			padding: 16px;

			p {
				font-weight: 400;
				line-height: 1;
				font-size: 15.4px;
				margin-bottom: 16px;
				margin-top: 0;
			}

			.headline {
				text-transform: uppercase;
				color: white;
			}
			.time-ago {
				color: hsla(0, 0%, 100%, 0.7);
			}
			.story-icon {
				position: absolute;
				right: 15px;
				bottom: 15px;
				font-size: 18px;
				color: white;
			}
		}
	}
`

export const StorySettingsRow = styled.div`
	width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'fit-content')};
	display: flex;
	align-items: center;
	justify-content: ${({ $justifyContent }) => $justifyContent ?? 'space-between'};
	flex-wrap: ${({ $flexWrap }) => $flexWrap ?? 'nowrap'};

	&:not(:last-of-type) {
		margin-bottom: ${({ theme }) => theme.spacing(2)};
	}

	.property-title {
		font-size: 18px;
		color: ${({ theme }) => theme.palette.text.primary};
		padding: ${({ theme }) => theme.spacing(1)};
	}

	.property-description {
		font-size: 14px;
		line-height: 20px;
		color: ${({ theme }) => theme.palette.text.secondary};
		padding: ${({ theme }) => theme.spacing(1)};
	}
`

export const StorySettingsPropertyTitle = styled(Typography)`
	color: ${({ theme }) => theme.palette.text.primary};
	padding: ${({ theme }) => theme.spacing(1)};
	font-size: 18px;
`
StorySettingsPropertyTitle.defaultProps = {
	variant: 'h3',
}

export const StorySettingsGridRow = styled(Box)`
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr auto;

	.MuiOutlinedInput-root {
		input {
			padding: ${({ theme }) => theme.spacing(1.5, 1)};
		}
	}

	.MuiOutlinedInput-multiline {
		padding: ${({ theme }) => theme.spacing(3, 1, 1.5, 1)};
	}

	.property-title {
		font-size: 18px;
		color: ${({ theme }) => theme.palette.text.primary};
		padding: ${({ theme }) => theme.spacing(1)};
	}

	.property-description {
		font-size: 14px;
		line-height: 20px;
		color: ${({ theme }) => theme.palette.text.secondary};
		padding: ${({ theme }) => theme.spacing(1)};
	}
`

export const BrandWrapper = styled.div`
	position: relative;
	border: ${(props) => (props.$isActive ? snackeet_blue : 'transparent')} solid 2px;
	border-radius: 8px;

	&:hover {
		border-color: ${snackeet_yellow};
	}
`

export const ColorCircle = styled.div`
	position: absolute;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	left: ${({ $left }) => $left}px;
	top: 2px;
	background-color: ${({ $color }) => $color};
	box-shadow: ${({ theme }) => theme.shadows[2]};
	transition: transform 200ms ease-in-out;

	&:hover {
		transform: scale(1.08);
	}
`

export const EmailUpdateWrapper = styled(Box)`
	.MuiOutlinedInput-root {
		input {
			padding: ${({ theme }) => theme.spacing(1.5, 1)};
		}
	}

	.MuiOutlinedInput-multiline {
		padding: ${({ theme }) => theme.spacing(1.5, 1)};
	}
`

// Fin

export const MembersContainer = styled.div`
	width: 100%;
	height: max-content;
	/* box-shadow: ${({ theme }) => theme.shadows[3]}; */
	border-radius: 10px;
	padding: 0 ${({ theme }) => theme.spacing(2)};

	.detailLine {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.numberContainer {
		width: max-content;
		display: flex;
		align-items: center;
		justify-content: space-evenly;
	}

	.numberTitle {
		font-size: 16px;
		margin-right: ${({ theme }) => theme.spacing(1)};
	}

	.number {
		height: 36px;
		width: 36px;
		color: ${({ theme }) => theme.palette.primary.main};
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 18px;
		font-weight: bold;
		border: 2px solid ${({ theme }) => theme.palette.primary.main};
		border-radius: 50%;
	}

	.seats-available {
		font-size: 20px;
		color: ${({ theme }) => theme.palette.primary.main};
		font-weight: bold;
	}

	.seats-unavailable {
		font-size: 20px;
		color: ${({ theme }) => theme.palette.error.main};
		font-weight: bold;
	}
`

export const MembersList = styled.table`
	padding: 10px;
	width: 100%;
	margin: ${({ theme }) => theme.spacing(2)} auto;
	text-align: left;
	border-collapse: collapse;

	thead {
		padding: 8px;
		border-bottom: 2px solid ${({ theme }) => theme.palette.action.hover};
	}
	tbody {
		padding: 20px;
	}
`

export const MemberCardContainer = styled.tr`
	height: 40px;
	font-size: 16px;

	&:hover {
		background-color: ${({ theme }) => theme.palette.action.hover};
	}

	.invitationStatus {
		color: ${(props) => props.status === 'pending' && props.theme.palette.secondary.main};
		color: ${(props) => props.status === 'rejected' && props.theme.palette.error.main};
		color: ${(props) => props.status === 'accepted' && props.theme.palette.success.main};
	}
`

export const LogicBadge = styled(Badge)`
	.MuiBadge-colorPrimary {
		background-color: ${({ theme }) => theme.palette.success.main};
	}
	.MuiBadge-anchorOriginTopRightRectangular {
		top: ${({ $top }) => $top ?? 5}px;
		right: ${({ $right }) => $right ?? 5}px;
	}
`
LogicBadge.defaultProps = {
	variant: 'dot',
	overlap: 'rectangular',
	component: 'div',
}
