import { CogIcon, TrashIcon } from '@heroicons/react/outline';
import CloseIcon from '@mui/icons-material/Close';
import { Button, CircularProgress, Drawer, IconButton, SvgIcon, Toolbar, Typography } from '@mui/material';
import Fade from '@mui/material/Fade';
import styled from '@styled';
import { PREVIEW_HEIGHT, PREVIEW_WIDTH, SMALL_LOGO_URL } from 'lib/constants';
import PropTypes from 'prop-types';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { getCustomerRights } from 'redux/customer/selectors';
import { useDebounce } from 'use-debounce';
const PreviewDrawer = styled(Drawer)`
	.MuiDrawer-paper {
		min-width: 400px;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		background-color: rgb(245, 245, 245);
	}

	.MuiToolbar-root {
		display: flex;
		justify-content: space-between;
		width: 100%;
		padding: ${({
  theme
}) => theme.spacing(0, 2)};
		background-color: #fff;
	}

	.drawer-content {
		padding: ${({
  theme
}) => theme.spacing(2)};
		display: flex;
		flex-direction: column;
		align-items: center;
		row-gap: ${({
  theme
}) => theme.spacing(2)};
	}
`;
PreviewDrawer.defaultProps = {
  sx: {
    // Make sure it stays above the modal
    zIndex: 'modal'
  }
};
const TopButtonsRow = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-evenly;

	button {
		background-color: #fff;
	}
`;
const TemplatePreviewContainer = styled.div`
	position: relative;
	aspect-ratio: ${PREVIEW_WIDTH} / ${PREVIEW_HEIGHT};
	height: clamp(250px, calc(100vh - 300px), 550px);

	iframe {
		border: none;
		border-radius: 4px;
		width: 100%;
		height: 100%;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 4px;
	}

	.loader {
		position: absolute;
		inset: 0;
		z-index: 1;
		background: inherit;
		display: grid;
		place-items: center;
	}
`;
const LogoLoaderContainer = styled.div`
	position: absolute;
	inset: 0;
	display: grid;
	place-items: center;
	background-color: #fff;
	box-shadow: ${({
  theme
}) => theme.shadows[1]}px;
	z-index: 1;

	img {
		object-fit: contain;
	}
`;
const StoryTemplatePreview = forwardRef(function StoryTemplatePreview({
  selectedTemplate,
  onTemplateConfirm,
  openConfirmationModal,
  openAccessModal
}, ref) {
  const currentCustomerRights = useSelector(getCustomerRights);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [debouncedIsLoaded] = useDebounce(isLoaded, 300);
  const open = () => {
    setIsOpen(true);
  };
  const close = () => {
    setIsOpen(false);
    setIsLoaded(false);
  };
  useImperativeHandle(ref, () => ({
    open,
    close
  }));
  return <PreviewDrawer anchor='right' open={isOpen} onClose={close}>
			<Toolbar>
				<Typography variant='h1'>{selectedTemplate?.name}</Typography>

				<IconButton aria-label='close-preview' onClick={close} size='large'>
					<CloseIcon />
				</IconButton>
			</Toolbar>

			<div className='drawer-content'>
				{selectedTemplate.story ? <>
						<TopButtonsRow>
							{currentCustomerRights.isAdmin && <Button variant='outlined' size='small' onClick={openAccessModal} startIcon={<SvgIcon>
											<CogIcon />
										</SvgIcon>}>
									<FormattedMessage id='modals.templates.button.manage' />
								</Button>}
							<Button color='error' variant='outlined' size='small' onClick={openConfirmationModal} startIcon={<SvgIcon>
										<TrashIcon />
									</SvgIcon>}>
								<FormattedMessage id='button.delete' />
							</Button>
						</TopButtonsRow>

						<TemplateImage src={selectedTemplate.story.snapshot_url_png} isLoaded={isLoaded} onLoad={() => setIsLoaded(true)} />
					</> : <TemplatePreviewContainer>
						<iframe src={`${process.env.NEXT_PUBLIC_SNACKEET_URL}/${selectedTemplate?.story_id}?preview`} onLoad={() => setIsLoaded(true)} />

						{!debouncedIsLoaded && <div className='loader'>
								<CircularProgress color='secondary' />
							</div>}
					</TemplatePreviewContainer>}

				<Button variant='contained' color='primary' onClick={onTemplateConfirm}>
					<FormattedMessage id='modals.templates.button.choose' />
				</Button>
			</div>
		</PreviewDrawer>;
});
StoryTemplatePreview.propTypes = {
  selectedTemplate: PropTypes.object,
  confirmCreate: PropTypes.func,
  openConfirmationModal: PropTypes.func,
  openAccessModal: PropTypes.func
};
export default StoryTemplatePreview;
const DEFAULT_TEMPLATE_IMAGE = '/static/images/vertical-placeholder.jpeg';
function TemplateImage({
  src = DEFAULT_TEMPLATE_IMAGE,
  isLoaded,
  onLoad
}) {
  return <TemplatePreviewContainer>
			{/* Actual image, hidden initially */}
			<img src={src} style={{
      opacity: isLoaded ? 1 : 0
    }} onLoad={onLoad} onError={onLoad} alt='Template Preview' />

			{/* Logo displayed until image is loaded */}
			<Fade in={!isLoaded} appear={true}>
				<LogoLoaderContainer>
					<img src={SMALL_LOGO_URL} alt='Logo Placeholder' />
				</LogoLoaderContainer>
			</Fade>
		</TemplatePreviewContainer>;
}