import { TrashIcon } from '@heroicons/react/outline';
import { Check } from '@mui/icons-material';
import { Box, Button, DialogActions, IconButton, SvgIcon, TextField, Typography } from '@mui/material';
import { ChipVariable, DefaultVariable, ModalContent } from 'components/styled/Modals';
import { LEADERBOARD_VARIABLES, VARIABLE_TYPE_OPTIONS, VARIABLES_TYPES_COLORS } from 'lib/constants';
import { variableExists } from 'lib/utils';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { forwardRef, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import slugify from 'slugify';
import { useImmer } from 'use-immer';
import { ModalWrapper } from '../ModalWrapper';
const ShareVariablesModal = forwardRef(function ShareVariablesModal({
  storyType,
  currentStoryVariables,
  assignVariable,
  removeVariable,
  addNewVariable
}, ref) {
  const createVariableRef = useRef();
  const [{
    selectedVariableNames,
    fieldError,
    newVariable
  }, setState] = useImmer({
    selectedVariableNames: [],
    fieldError: null,
    newVariable: {
      name: '',
      type: 'text'
    }
  });
  function resetState() {
    setState(draft => {
      draft.fieldError = null;
      draft.newVariable = {
        name: '',
        type: 'text'
      };
    });
  }
  function handleNewVariableNameChange({
    target: {
      value
    }
  }) {
    const slugValue = slugify(value, {
      remove: /[$&*+~%\<>§\\\/;`=.(){}?,'"!:@#^|]/g
    });
    setState(draft => {
      draft.fieldError = null;
      draft.newVariable.name = slugValue;
    });
  }
  function handleNewVariableTypeChange(value) {
    setState(draft => {
      draft.newVariable.type = value;
    });
  }
  function onConfirmNewVariable() {
    if (_.isEmpty(newVariable.name)) {
      setState(draft => {
        draft.fieldError = 'Variable cannot be empty!';
      });
      return;
    }
    if (variableExists({
      name: newVariable.name,
      currentVariables: currentStoryVariables
    })) {
      setState(draft => {
        draft.fieldError = 'Variable already exists !';
      });
      return;
    }
    addNewVariable(_.cloneDeep(newVariable));
    resetState();
  }
  function handleClick(name) {
    setState(draft => {
      if (_.includes(draft.selectedVariableNames, name)) {
        _.remove(draft.selectedVariableNames, variableName => variableName === name);
      } else {
        draft.selectedVariableNames.push(name);
      }
    });
  }
  function onConfirm() {
    resetState();
    assignVariable(selectedVariableNames);
    createVariableRef.current.close();
    ref.current.close();
  }
  function onOpen() {
    setState(draft => {
      draft.selectedVariableNames = _.flatMap(currentStoryVariables, storyVariable => {
        if (!storyVariable.param) return [];
        return storyVariable.name;
      });
    });
  }
  return <>
			<ModalWrapper size='md' ref={ref} title={<FormattedMessage id='modals.variables.header' values={{
      count: 2
    }} />} onClose={resetState} onOpen={onOpen} hasCloseButton={false} disableBackdropClick>
				<ModalContent>
					<div className='variables-container'>
						{storyType === 'classic' && <div className='variables-default'>
								<Typography variant='body2' className='variables-title'>
									<FormattedMessage id='modals.variables.leaderboard.subtitle' />
								</Typography>
								<div className='sub-container'>
									{currentStoryVariables.filter(variable => variable.default && _.includes(LEADERBOARD_VARIABLES, variable.name)).sort((a, b) => a.param - b.param).map(variable => <DefaultVariable key={variable.name} onClick={() => handleClick(variable.name)} isAssigned={_.includes(selectedVariableNames, variable.name)}>
												<Typography variant='body2'>{variable.name}</Typography>
												<Box textAlign='center'>
													<ChipVariable size='small' label={<Typography variant='body2'>
																<FormattedMessage id={`edition_menu.form.field_type.${variable.type}`} />
															</Typography>} $bgColor={VARIABLES_TYPES_COLORS[variable.type]} />
												</Box>
											</DefaultVariable>)}
								</div>
							</div>}

						<div className='variables-default'>
							<Typography variant='body2' className='variables-title'>
								<FormattedMessage id='modals.variables.default.subtitle' />
							</Typography>
							<div className='sub-container'>
								{currentStoryVariables.filter(variable => variable.default && !_.includes(LEADERBOARD_VARIABLES, variable.name)).sort((a, b) => a.param - b.param).map(variable => <DefaultVariable key={variable.name} onClick={() => handleClick(variable.name)} isAssigned={_.includes(selectedVariableNames, variable.name)}>
											<Typography variant='body2'>{variable.name}</Typography>
											<Box textAlign='center'>
												<ChipVariable size='small' label={<Typography variant='body2'>
															<FormattedMessage id={`edition_menu.form.field_type.${variable.type}`} />
														</Typography>} $bgColor={VARIABLES_TYPES_COLORS[variable.type]} />
											</Box>
										</DefaultVariable>)}
							</div>
						</div>

						<div className='variables-custom'>
							<Box display='flex' alignItems='center' marginY='10px'>
								<Typography variant='body2' className='variables-title variable-perso'>
									<FormattedMessage id='modals.variables.custom.title' />
								</Typography>
								<Button variant='contained' color='primary' size='small' onClick={() => createVariableRef.current.open()}>
									<FormattedMessage id='modals.variables.custom.create' />
								</Button>
							</Box>

							<div className='sub-container'>
								{currentStoryVariables.filter(variable => !variable.default).sort((a, b) => a.param - b.param).map(variable => <DefaultVariable key={variable.name} onClick={() => handleClick(variable.name)} isAssigned={_.includes(selectedVariableNames, variable.name)}>
											<Typography variant='body2'>{variable.name}</Typography>
											<Box textAlign='center'>
												<ChipVariable size='small' label={<Typography variant='body2'>
															<FormattedMessage id={`edition_menu.form.field_type.${variable.type}`} />
														</Typography>} $bgColor={VARIABLES_TYPES_COLORS[variable.type]} />
											</Box>
											{!variable.isUsed && <Box display='flex' alignItems='center' justifyContent='flex-end'>
													<IconButton size='small' onClick={evt => {
                    evt.preventDefault();
                    evt.stopPropagation();
                    removeVariable(variable.name);
                  }}>
														<SvgIcon>
															<TrashIcon />
														</SvgIcon>
													</IconButton>
												</Box>}
										</DefaultVariable>)}
							</div>
						</div>
					</div>
				</ModalContent>
				<DialogActions>
					<Button variant='contained' color='secondary' onClick={onConfirm} sx={{
          float: 'right',
          margin: 2
        }}
        // disabled={_.isEqual(selectedVariableNames, _.map(currentStoryVariables, 'name'))}
        >
						<FormattedMessage id='common.theming.confirm_button' />
					</Button>
				</DialogActions>
			</ModalWrapper>

			<ModalWrapper size='sm' ref={createVariableRef} title={<FormattedMessage id='modals.variables.custom.create' />}>
				<ModalContent>
					<Typography variant='body2' className='custom-subtitle'>
						<FormattedMessage id='modals.variables.custom.subtitle' />
					</Typography>
					<div className='create-variable'>
						<div className='form-variable'>
							<Box>
								<Typography variant='h4'>
									<FormattedMessage id='modals.variables.create_variable.type' />
								</Typography>
								<Box px={2} py={1} width='100%' display='grid' gridTemplateColumns='1fr 1fr 1fr 1fr' gap={3}>
									{_.map(VARIABLE_TYPE_OPTIONS, type => <Box key={type.value} display='flex' justifyContent='space-between' alignItems='center' onClick={() => handleNewVariableTypeChange(type.value)} p={1} sx={{
                  border: `1.5px solid ${newVariable.type === type.value ? VARIABLES_TYPES_COLORS[type.value] : '#ccc'}`,
                  color: `${newVariable.type === type.value ? VARIABLES_TYPES_COLORS[type.value] : '#ccc'}`,
                  borderRadius: '4px',
                  cursor: 'pointer'
                }}>
											<b>{type.text}</b>
											{newVariable.type === type.value && <Check size='small' />}
										</Box>)}
								</Box>
								<Box height={30}>
									<Typography variant='body2'>
										<FormattedMessage id={`modals.variables.description.${newVariable.type}`} />
									</Typography>
								</Box>
							</Box>
							<Box mb={1}>
								<Typography variant='h4' gutterBottom>
									<FormattedMessage id='modals.variables.create_variable.name' />
								</Typography>

								<TextField variant='outlined' fullWidth error={fieldError} placeholder='Custom variable' value={newVariable.name} onChange={handleNewVariableNameChange} helperText={fieldError} />
							</Box>
						</div>
						<Box display='flex' justifyContent='flex-end'>
							<Button variant='contained' color='primary' onClick={onConfirmNewVariable}>
								<FormattedMessage id='modals.variables.create_variable.create_btn' />
							</Button>
						</Box>
					</div>
				</ModalContent>
			</ModalWrapper>
		</>;
});
ShareVariablesModal.propTypes = {
  currentVariables: PropTypes.array,
  storyType: PropTypes.string,
  variableParams: PropTypes.array,
  assignVariable: PropTypes.func,
  removeVariable: PropTypes.func,
  addNewVariable: PropTypes.func,
  newVariable: PropTypes.object,
  handleChangeNewVariableName: PropTypes.func,
  handleChangeNewVariableType: PropTypes.func,
  fieldError: PropTypes.string,
  onClose: PropTypes.func
};
export default ShareVariablesModal;