import { CodeIcon } from '@heroicons/react/outline';
import { AlternateEmailOutlined, CheckBoxOutlined, DateRangeOutlined, Delete, DragIndicator, Edit, ErrorOutlineOutlined, ImageOutlined, LanguageOutlined, LinkOutlined, ListOutlined, LooksOneOutlined, MessageOutlined,
// Input icons
PersonOutline, PhoneOutlined,
// Content icons
TitleOutlined, ViewHeadlineOutlined } from '@mui/icons-material';
import { Box, Button, Checkbox, FormControlLabel, IconButton, SvgIcon, TextField, Tooltip, Typography } from '@mui/material';
import { ColorInput, NumberInput } from 'atoms';
import { ConfirmationModal, VariablesModal } from 'components/Modals';
import { FieldSettingsContainer } from 'components/styled/Modals';
import { getMediaDimensions, getValidVariableTypes, isUrl, unassignFieldVariable, updateVariableData, variableExists } from 'lib/utils';
import _ from 'lodash';
import { forwardRef, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import slugify from 'slugify';
import { useImmer } from 'use-immer';
import { ElementImageImport } from '../../containers/Editor/StoryEditor/MediaAssets/ImageImport';
const INPUT_SUB_TYPES_LIST = ['firstname', 'lastname', 'email', 'phone', 'text', 'number', 'date', 'select', 'website', 'consent'];
const INPUT_ICONS_LIST = {
  firstname: <PersonOutline />,
  lastname: <PersonOutline />,
  email: <AlternateEmailOutlined />,
  phone: <PhoneOutlined />,
  text: <MessageOutlined />,
  number: <LooksOneOutlined />,
  date: <DateRangeOutlined />,
  select: <ListOutlined />,
  website: <LanguageOutlined />,
  consent: <CheckBoxOutlined />
};
const CONTENT_ICONS_LIST = {
  title: <TitleOutlined />,
  paragraph: <ViewHeadlineOutlined />,
  disclamer: <ErrorOutlineOutlined />,
  image: <ImageOutlined />,
  button: <LinkOutlined />,
  iframe: <CodeIcon />
};
const formDateFormatOptions = [{
  text: <FormattedMessage id='edition_menu.form.field_settings.date_format.eu' />,
  value: 'DD/MM/YYYY'
}, {
  text: <FormattedMessage id='edition_menu.form.field_settings.date_format.us' />,
  value: 'MM/DD/YYYY'
}];
const formFieldInputProps = {
  readOnly: true,
  disabled: true
};
const ContentElementSettings = forwardRef(function ContentElementSettings({
  intl,
  element,
  canDelete,
  handleRemoveContentElement,
  handleChangeElementProperty,
  handleChangeElementStyle,
  isDragging,
  currentVariables,
  currentPage,
  setVariables,
  recentColors,
  brandColors,
  recentFonts,
  brandFontFamily,
  onRecentColorsUpdate,
  theme,
  ...delegatedProps
}, ref) {
  const changeVariableRef = useRef();
  const advancedSettingsRef = useRef();
  const confirmRef = useRef();
  const listSettingsRef = useRef();
  const [newVariable, setNewVariable] = useImmer({
    name: '',
    type: 'text'
  });
  const [fieldError, setFieldError] = useState(null);
  function openVariablesModal() {
    changeVariableRef.current.open();
  }
  function handleNewVariableNameChange(value) {
    setFieldError(null);
    const slugValue = slugify(value, {
      remove: /[*+~%\<>§/;`=.(){}?,'"!:@#^|]/g
    });
    setNewVariable(draft => {
      draft.name = slugValue;
    });
  }
  function handleNewVariableTypeChange(value) {
    setNewVariable(draft => {
      draft.type = value;
    });
  }
  function addVariable() {
    if (_.isEmpty(newVariable.name)) {
      return setFieldError('Variable cannot be empty!');
    }
    if (variableExists({
      name: newVariable.name,
      currentVariables
    })) {
      return setFieldError('Variable already exists !');
    }
    setVariables(draft => {
      draft.push({
        ...newVariable,
        default: false,
        isUsed: false
      });
    });
    setNewVariable(draft => {
      draft.name = '';
      draft.type = 'text';
    });
    setFieldError(null);
  }
  function removeVariable(name) {
    const index = currentVariables.findIndex(variable => variable.name === name && !variable.default);
    if (index >= 0) {
      setVariables(draft => {
        draft.splice(index, 1);
      });
    }
  }
  function assignVariable(name) {
    // If form field already has variable, then clean up current variable
    if (element.variable) {
      const index = currentVariables.findIndex(variable => variable.name === element.variable);
      if (index >= 0) {
        setVariables(draft => {
          draft[index] = unassignFieldVariable(currentVariables[index], element._id);
        });
      }
    }

    // Update variable of form field
    handleChangeElementProperty(element._id, 'variable', name);

    // Update variables
    const idx = currentVariables.findIndex(variable => variable.name === name);
    if (idx >= 0) {
      setVariables(draft => {
        draft[idx] = updateVariableData(currentVariables[idx], {
          page: {
            _id: currentPage._id,
            type: currentPage.type
          },
          field: element._id
        });
      });
    }
    setNewVariable(draft => {
      draft.name = '';
      draft.type = 'text';
    });
    setFieldError(null);
    changeVariableRef.current.close();
  }
  function onCloseVariablesModal() {
    setNewVariable(draft => {
      draft.name = '';
      draft.type = 'text';
    });
    setFieldError(null);
  }
  async function handleChangeImage(value) {
    const {
      height,
      width
    } = await getMediaDimensions({
      type: 'image',
      url: value
    });
    handleChangeElementProperty(element._id, 'value', value);
    if (height) {
      handleChangeElementProperty(element._id, 'height', height);
    }
    if (width) {
      handleChangeElementProperty(element._id, 'width', width);
    }
  }
  function onRemoveField() {
    handleRemoveContentElement(element._id);
    confirmRef.current.close();
  }
  function renderInputField(type, sub_type) {
    switch (type) {
      case 'input':
        switch (sub_type) {
          // case 'date':
          // 	return (
          // 		<div className='placeholder-container'>
          // 			<Tooltip
          // 				placement='bottom'
          // 				title={intl.messages[`edition_menu.form.field_type.${sub_type}`]}
          // 			>
          // 				<div className='sub-type-icon'>
          // 					<SvgIcon>{ICONS_LIST[sub_type]}</SvgIcon>
          // 				</div>
          // 			</Tooltip>
          // 			<Dropdown
          // 				variant='outlined'
          // 				options={formDateFormatOptions}
          // 				value={field.settings.format}
          // 				onChange={({ target: { value } }) => handleChangeFieldSettings(field._id, 'format', value)}
          // 			/>
          // 		</div>
          // 	)
          case 'consent':
            return <div className='consent-container'>
								<Tooltip placement='bottom' title={intl.messages[`edition_menu.form.field_type.${sub_type}`]}>
									<div className='sub-type-icon'>
										<SvgIcon>{INPUT_ICONS_LIST[sub_type]}</SvgIcon>
									</div>
								</Tooltip>
								<TextField fullWidth variant='outlined' value={element.label} multiline minRows={2} onChange={evt => handleChangeElementProperty(element._id, 'label', evt.target.value)} />
							</div>;
          default:
            return <div className='placeholder-container'>
								<Tooltip placement='bottom' title={intl.messages[`edition_menu.form.field_type.${sub_type}`]}>
									<div className='sub-type-icon'>
										<SvgIcon>{INPUT_ICONS_LIST[sub_type]}</SvgIcon>
									</div>
								</Tooltip>
								<TextField fullWidth variant='outlined' value={element.placeholder} onChange={evt => handleChangeElementProperty(element._id, 'placeholder', evt.target.value)} />
							</div>;
        }
      case 'content':
        switch (element.sub_type) {
          case 'image':
            return <div className='consent-container'>
								<Tooltip placement='bottom' title={intl.messages[`edition_menu.form.field_type.${sub_type}`]}>
									<div className='sub-type-icon'>
										<SvgIcon>{CONTENT_ICONS_LIST[sub_type]}</SvgIcon>
									</div>
								</Tooltip>
								<Box width='400px' display='grid' gridTemplateColumns='1fr auto' alignItems='center'>
									<ElementImageImport handleAnswerUpdate={value => handleChangeImage(value)} url={element.value} />
									{_.isEmpty(element?.value) && <Box pl={2}>
											<Typography variant='body2' color='error'>
												{intl.messages['edition_menu.answer.image_url.error']}
											</Typography>
										</Box>}
								</Box>
							</div>;
          case 'button':
            return <div className='placeholder-container'>
								<Tooltip placement='bottom' title={intl.messages[`edition_menu.form.field_type.${sub_type}`]}>
									<div className='sub-type-icon'>
										<SvgIcon>{CONTENT_ICONS_LIST[sub_type]}</SvgIcon>
									</div>
								</Tooltip>
								<TextField fullWidth variant='outlined' value={element.value} onChange={evt => handleChangeElementProperty(element._id, 'value', evt.target.value)} />
							</div>;
          default:
            return <div className='consent-container'>
								<Tooltip placement='bottom' title={intl.messages[`edition_menu.form.field_type.${sub_type}`]}>
									<div className='sub-type-icon'>
										<SvgIcon>{CONTENT_ICONS_LIST[sub_type]}</SvgIcon>
									</div>
								</Tooltip>
								<TextField fullWidth variant='outlined' value={element.value} multiline minRows={2} onChange={evt => handleChangeElementProperty(element._id, 'value', evt.target.value)} />
							</div>;
        }
    }
  }
  function renderAdvancedSettings(sub_type) {
    switch (sub_type) {
      case 'title':
      case 'paragraph':
        return <Box width='100%'>
						<div className='properties-wrapper'>
							<div className='property-line'>
								<Typography variant='body1' component='label' className='property-field'>
									<FormattedMessage id='common.size' />
								</Typography>
								<div className='property-content'>
									<NumberInput initial_value={getFontSize(element)} onUpdate={value => handleChangeElementStyle(element._id, 'fontSize', `${value}px`)} maxValue={100} minValue={6} units='px' inputStep={1} presetValues={[6, 8, 10, 12, 14, 16, 18, 21, 24, 26, 28, 32, 36, 42, 48, 56, 64, 72, 80, 96]} />
								</div>
							</div>
							<ColorInput value={getColor(element, theme)} updateValue={value => handleChangeElementStyle(element._id, 'color', value)} recentColors={recentColors} brandColors={brandColors} updateRecentColors={onRecentColorsUpdate} />
						</div>
					</Box>;
      case 'button':
        return <Box width='100%'>
						<Box width='100%' display='flex' alignItems='center'>
							<Typography style={{
              marginRight: '4px'
            }}>
								<b>URL</b>
							</Typography>
							<TextField fullWidth variant='outlined' placeholder={intl.messages['edition_menu.form.label.placeholder']} value={element.url || ''} onChange={evt => handleChangeElementProperty(element._id, 'url', evt.target.value)} error={!isUrl(element.url)?.success} />
						</Box>
						{!isUrl(element.url)?.success && <Box ml={4}>
								<Typography variant='body2' color='error'>
									{intl.messages['edition_menu.attachment.outlink_link_error']}
								</Typography>
							</Box>}

						<div className='properties-wrapper'>
							<div className='property-line'>
								<Typography variant='body1' component='label' className='property-field'>
									<FormattedMessage id='common.size' />
								</Typography>
								<div className='property-content'>
									<NumberInput initial_value={parseInt(element?.styles?.fontSize)} onUpdate={value => handleChangeElementStyle(element._id, 'fontSize', `${value}px`)} maxValue={450} minValue={6} units='px' inputStep={1} presetValues={[6, 8, 10, 12, 14, 16, 18, 21, 24, 26, 28, 32, 36, 42, 48, 56, 64, 72, 80, 96]} />
								</div>
							</div>
							<div className='property-line'>
								<Typography variant='body1' component='label' className='property-field'>
									<FormattedMessage id='common.radius' />
								</Typography>
								<div className='property-content'>
									<NumberInput initial_value={parseInt(element?.styles?.borderRadius || '0px')} onUpdate={value => handleChangeElementStyle(element._id, 'borderRadius', `${value}px`)} maxValue={100} units='px' inputStep={1} presetValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]} />
								</div>
							</div>
						</div>
						<div className='properties-wrapper'>
							<ColorInput value={element?.styles.color} updateValue={value => handleChangeElementStyle(element._id, 'color', value)} recentColors={recentColors} brandColors={brandColors} updateRecentColors={onRecentColorsUpdate} />
							<ColorInput value={element?.styles.backgroundColor} updateValue={value => handleChangeElementStyle(element._id, 'backgroundColor', value)} label={intl.messages['common.background_color']} recentColors={recentColors} brandColors={brandColors} updateRecentColors={onRecentColorsUpdate} />
						</div>
					</Box>;
      default:
        return <div />;
    }
  }
  return <>
			<FieldSettingsContainer ref={ref} $isDragging={isDragging} {...delegatedProps}>
				<div className='drag-handle'>
					<DragIndicator />
				</div>

				<Box width='100%'>
					{element.type !== 'content' && element.sub_type !== 'consent' && <Box className='field-title'>
							<Typography variant='body1' noWrap>
								<b>{intl.messages['edition_menu.form.label.title']}</b>
							</Typography>
							<TextField fullWidth variant='outlined'
          // disableUnderline
          placeholder={intl.messages['edition_menu.form.label.placeholder']} value={element.label || ''} onChange={evt => handleChangeElementProperty(element._id, 'label', evt.target.value)} />
						</Box>}

					{renderInputField(element.type, element.sub_type)}

					{element.type !== 'content' ? <Box sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1
        }}>
							<Tooltip title={<FormattedMessage id='edition_menu.form.field_description.tooltip.required' />} placement='bottom'>
								<div>
									<FormControlLabel value='start' control={<Checkbox onChange={({
                target: {
                  checked
                }
              }) => handleChangeElementProperty(element._id, 'required', checked)} checked={element.required} color='primary' />} label={<FormattedMessage id='edition_menu.form.field_description.required' />} labelPlacement='start' sx={{
                ml: 0,
                mr: 3
              }} />
								</div>
							</Tooltip>

							{element?.variable ? <>
									<Tooltip title={<div>
												<FormattedMessage id='edition_menu.form.variable.tooltip.title' />
												<FormattedMessage id='edition_menu.form.variable.tooltip.subtitle' />
											</div>} placement='bottom'>
										<Typography variant='body1'>
											<b>Var:</b> {element?.variable}
										</Typography>
									</Tooltip>
									<IconButton size='small' onClick={openVariablesModal}>
										<Edit fontSize='small' />
									</IconButton>
								</> : <Tooltip title={<div>
											<FormattedMessage id='edition_menu.form.variable.tooltip.title' />
											<FormattedMessage id='edition_menu.form.variable.tooltip.subtitle' />
										</div>} placement='bottom'>
									<Button variant='outlined' size='small' onClick={openVariablesModal}>
										<FormattedMessage id='edition_menu.variables.add' values={{
                count: 1
              }} />
									</Button>
								</Tooltip>}

							<Box>{renderAdvancedSettings(element.sub_type)}</Box>
						</Box> : <Box mt={1}>{renderAdvancedSettings(element.sub_type)}</Box>}
				</Box>

				<Tooltip title={canDelete ? intl.messages['button.delete'] : intl.messages['edition_menu.form.field.delete_error']} placement='bottom'>
					<div>
						<IconButton size='small' disabled={!canDelete} onClick={() => confirmRef.current.open()}>
							<Delete />
						</IconButton>
					</div>
				</Tooltip>
			</FieldSettingsContainer>

			<ConfirmationModal size='sm' confirmationModalRef={confirmRef} title={<FormattedMessage id='modals.form.field_remove.title' />} onConfirm={onRemoveField} firstLine={<FormattedMessage id='modals.form.field_remove.sub_title' />} secondLine={<FormattedMessage id='modal.file_deletion.confirmation.instructions' />} confirmText={<FormattedMessage id='common.yes' />} cancelText={<FormattedMessage id='common.no' />} />

			{/* <FieldAdvancedSettingsModal
    ref={advancedSettingsRef}
    field_id={field._id}
    field_settings={field.settings}
    field_type={field.type}
    handleChangeFieldSettings={handleChangeSettings}
    /> */}

			{/* <ListSettingsModal
    ref={listSettingsRef}
    field_id={field._id}
    field_settings={field.settings}
    handleChangeFieldSettings={handleChangeSettings}
    /> */}

			<VariablesModal ref={changeVariableRef} currentVariables={currentVariables} currentVariable={element?.variable} allowType={getValidVariableTypes('field', element)} assignVariable={assignVariable} removeVariable={removeVariable} addNewVariable={addVariable} newVariable={newVariable} handleChangeNewVariableName={handleNewVariableNameChange} handleChangeNewVariableType={handleNewVariableTypeChange} fieldError={fieldError} onClose={onCloseVariablesModal} />
		</>;
});
ContentElementSettings.displayName = 'ContentElementSettings';
export default ContentElementSettings;

// Helper functions to refactor and init value

function getFontSize(element) {
  if (element.styles.fontSize) {
    return parseInt(element.styles.fontSize);
  } else if (element.sub_type === 'title') {
    return 28;
  } else if (element.sub_type === 'paragraph') {
    return 16;
  }
}
function getColor(element, theme) {
  if (element.styles.color) {
    return element.styles.color;
  } else if (theme === 'light') {
    return 'rgba(0, 0, 0, 100)';
  } else if (theme === 'dark') {
    return 'rgba(255, 255, 255, 100)';
  }
}