import { TrashIcon } from '@heroicons/react/outline';
import { Box, IconButton, SvgIcon, Tooltip } from '@mui/material';
import styled from '@styled';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
export const ListContainer = styled(Box)``;
ListContainer.defaultProps = {
  width: '100%',
  height: '100%',
  overflow: 'auto',
  display: 'flex',
  flexWrap: 'wrap',
  gap: 2,
  p: 0.5
};
export const AssetNameContainer = styled.div`
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.5);
	backdrop-filter: blur(3px);
	color: #fff;

	span {
		width: 100%;
		padding: 2px 4px 1px;
		max-height: 4em;
		font-size: 10px;
		line-height: 1.4em;
		word-wrap: break-word;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		font-family: Poppins, sans-serif;
	}
`;
export function AssetName({
  name
}) {
  if (!name) return null;
  return <AssetNameContainer>
			<span title={name}>{name}</span>
		</AssetNameContainer>;
}
AssetName.propTypes = {
  name: PropTypes.string
};
export const MediaAssetIconButton = styled(IconButton)`
	opacity: 0;
	color: #fff;
	background-color: rgba(0, 0, 0, 0.6);
	transition:
		opacity ease-in-out 0.3s,
		transform 0.1s ease-out,
		background-color 0.1s ease-out;
	&:hover {
		transform: scale(1.05);
		background-color: rgba(0, 0, 0, 1);
	}
`;
export function DeleteButton({
  fileId,
  onDelete
}) {
  return <Box sx={{
    position: 'absolute',
    top: 5,
    right: 5
  }}>
			<Tooltip title={<FormattedMessage id='button.delete' />} disableInteractive>
				<MediaAssetIconButton size='small' onClick={evt => {
        evt.preventDefault();
        evt.stopPropagation();
        onDelete(fileId);
      }}>
					<SvgIcon fontSize='small'>
						<TrashIcon />
					</SvgIcon>
				</MediaAssetIconButton>
			</Tooltip>
		</Box>;
}
DeleteButton.propTypes = {
  fileId: PropTypes.string,
  onDelete: PropTypes.func
};
export const MediaAssetCard = styled(Box)`
	display: block;
	place-items: center;
	position: relative;
	overflow: hidden;
	box-shadow: ${({
  theme
}) => theme.shadows[3]};
	background-image: repeating-conic-gradient(#eeeeee5d 0 25%, transparent 0 50%);
	background-size: 16px 16px;

	&.selected {
		outline: 3px solid ${({
  theme
}) => theme.palette.primary.main}!important;
	}

	img,
	video {
		display: block; /* Important to make asset take full height of parent */
		width: 100%;
		height: 100%;
		cursor: pointer;
		object-fit: contain;

		/* Prevent dragging of asset */
		-webkit-user-drag: none;
		user-drag: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	&:hover {
		${MediaAssetIconButton} {
			opacity: 1;
		}
		outline: 2px solid ${({
  theme
}) => theme.palette.primary.main};
	}
`;
MediaAssetCard.defaultProps = {
  width: 'calc(20% - 16px)',
  height: 180
};