import { CodeIcon } from '@heroicons/react/outline';
import { AlternateEmailOutlined, DateRangeOutlined, ErrorOutlineOutlined, ImageOutlined, LanguageOutlined, LinkOutlined, ListOutlined, LooksOneOutlined, MessageOutlined,
// Input icons
PersonOutline, PhoneOutlined, PublishOutlined,
// Content icons
TitleOutlined, VerifiedUserOutlined, ViewHeadlineOutlined } from '@mui/icons-material';
import { Box, Button, DialogActions, Divider, IconButton, SvgIcon, TextField, Tooltip, Typography } from '@mui/material';
import { ColorInput, NumberInput } from 'atoms';
import { CustomTab, CustomTabs } from 'components/atoms/CustomTabs';
import { ConfirmationModal } from 'components/Modals';
import FontFamiliesDropdown from 'components/organisms/FontFamiliesDropdown';
import { ScrollableContainer } from 'components/styled';
import { AddNewAmpFieldContainer, AmpFormSettingsWrapper, FieldSettingsContainer } from 'components/styled/Modals';
import * as translation from 'lib/constants/InteractionTranslation';
import { getAttachmentElement } from 'lib/models';
import { generateVariableName, reorder, sleep, unassignFieldVariable, updateVariableData } from 'lib/utils';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getCurrentOrganizationId } from 'redux/organization/selectors';
import TemplatesSvc from 'services/TemplatesSvc';
import { useImmer } from 'use-immer';
import AmpFormPreview from './AmpFormPreview';
import ContentElementSettings from './ContentElementSettings';
const INPUT_SUB_TYPES_LIST = ['firstname', 'lastname', 'email', 'phone', 'text', 'number', 'date',
// 'select',
'website', 'consent'];
const CONTENT_SUB_TYPES_LIST = ['title', 'paragraph', 'image'
// 'disclaimer'
];
const SUCCESS_SUB_TYPES_LIST = ['title', 'paragraph', 'button'];
const INPUT_ICONS_LIST = {
  firstname: <PersonOutline />,
  lastname: <PersonOutline />,
  email: <AlternateEmailOutlined />,
  phone: <PhoneOutlined />,
  text: <MessageOutlined />,
  number: <LooksOneOutlined />,
  date: <DateRangeOutlined />,
  select: <ListOutlined />,
  website: <LanguageOutlined />,
  consent: <VerifiedUserOutlined />
};
const CONTENT_ICONS_LIST = {
  title: <TitleOutlined />,
  paragraph: <ViewHeadlineOutlined />,
  disclaimer: <ErrorOutlineOutlined />,
  image: <ImageOutlined />,
  button: <LinkOutlined />,
  iframe: <CodeIcon />
};

// States structure
/*
	_id: `amp-${nanoid}`,
		cta_text: <String>,
		theme: <Sring>,
		content: [<Field>],
		success: [<AttachmentElement>[content]],
		submit: {
			title: 'Submit',
			styles: {
				color: 'rgba(255, 255, 255, 1)',
				backgroundColor: 'rgba(82, 112, 232, 1)',
				borderWidth: '1px',
				fontSize: '32px',
				borderStyle: 'solid',
				borderColor: 'rgba(255, 255, 255, 1)',
				borderRadius: '30px',
			}
		}
*/

export default function AmpFormSettings({
  onPreviousStep,
  onCancel,
  onSubmitConfig,
  currentForm,
  currentPage,
  draftStory,
  recentColors,
  brandColors,
  recentFonts,
  brandFontFamily,
  onRecentColorsUpdate
}) {
  const intl = useIntl();
  const [states, setStates] = useImmer(initAmpForm({
    currentForm,
    variables: draftStory.variables,
    language: draftStory.story_settings.language
  }));
  const [tab, setTab] = useState('form');
  const [isAdding, setIsAdding] = useState(false);
  const [currentVariables, setVariables] = useImmer(draftStory.variables);
  const confirmationRef = useRef();
  const organizationId = useSelector(getCurrentOrganizationId);

  // Trigger when fields change to update current variables
  useEffect(() => {
    // Update current variables via fields
    // by adding new variables or update current variable trackingDatas
    const variables = _.cloneDeep(currentVariables);
    for (const element of states?.content) {
      if (element.type === 'input' && element.variable) {
        const index = _.findIndex(variables, ['name', element.variable]);
        if (index > -1) {
          // Update trackingDatas
          setVariables(draft => {
            draft[index] = updateVariableData(variables[index], {
              page: {
                _id: currentPage._id,
                type: currentPage.type
              },
              field: element._id
            });
          });
        } else if (index === -1) {
          // Create new variable
          setVariables(draft => {
            draft.push({
              name: element.variable,
              type: getVariableType(element.type),
              default: false,
              isUsed: true,
              trackingDatas: [{
                page: {
                  _id: currentPage._id,
                  type: currentPage.type
                },
                field: element._id
              }]
            });
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [states?.content]);
  async function handleAddContentElement(type, sub_type) {
    if (isAdding) return;
    setIsAdding(true);
    const newElement = type === 'input' ? initField(draftStory.story_settings.language, sub_type, currentVariables) : getAttachmentElement({
      type,
      sub_type
    });
    setStates(draft => {
      draft.content.push(newElement);
    });
    await sleep(300);
    setIsAdding(false);
  }
  function handleAddSuccessElement(type, sub_type) {
    const newElement = getAttachmentElement({
      type,
      sub_type
    });
    setStates(draft => {
      draft.success.push(newElement);
    });
  }

  // When remove Field, need to clean up variable
  function handleRemoveContentElement(_id) {
    const index = _.findIndex(states.content, field => field._id === _id);
    if (index > -1) {
      // Clean up variables
      const idx = currentVariables.findIndex(variable => variable.name === states.content[index]?.variable);
      if (idx >= 0) {
        setVariables(draft => {
          draft[idx] = unassignFieldVariable(currentVariables[idx], _id);
        });
      }
      setStates(draft => {
        draft.content.splice(index, 1);
      });
    }
  }
  function handleRemoveSuccessElement(_id) {
    const index = _.findIndex(states.success, field => field._id === _id);
    if (index > -1) {
      setStates(draft => {
        draft.success.splice(index, 1);
      });
    }
  }
  function onElementsDragEnd(result) {
    if (!result.destination) {
      return;
    }
    setStates(draft => {
      draft.content = reorder(draft.content, result.source.index, result.destination.index);
    });
  }
  function onSuccessElementsDragEnd(result) {
    if (!result.destination) {
      return;
    }
    setStates(draft => {
      draft.success = reorder(draft.success, result.source.index, result.destination.index);
    });
  }
  function handleChangeFormStyle(path, value) {
    setStates(draft => {
      draft.styles[path] = value;
    });
  }
  function handleChangeElementProperty(_id, path, value) {
    const idx = _.findIndex(states.content, element => element._id === _id);
    setStates(draft => {
      draft.content[idx][path] = value;
    });
  }
  function handleChangeElementStyle(_id, path, value) {
    const idx = _.findIndex(states.content, element => element._id === _id);
    setStates(draft => {
      draft.content[idx].styles[path] = value;
    });
  }
  function handleChangeSuccessElementProperty(_id, path, value) {
    const idx = _.findIndex(states.success, element => element._id === _id);
    setStates(draft => {
      draft.success[idx][path] = value;
    });
  }
  function handleChangeSucsessElementStyle(_id, path, value) {
    const idx = _.findIndex(states.success, element => element._id === _id);
    setStates(draft => {
      draft.success[idx].styles[path] = value;
    });
  }
  function handleChangeSubmitTitle(value) {
    setStates(draft => {
      draft.submit.title = value;
    });
  }
  function handleChangeSubmitStyles(path, value) {
    setStates(draft => {
      draft.submit.styles[path] = value;
    });
  }
  function handleSubmit() {
    onSubmitConfig({
      variables: currentVariables,
      newInteraction: states
    });
  }
  async function handleSaveAsTemplate() {
    await TemplatesSvc.saveAttachmentAsTemplate({
      attachment: states,
      name: 'Form Attachment',
      accessibleProjects: [draftStory.project],
      project: draftStory.project,
      organization_id: organizationId
    });
    handleSubmit();
  }
  function onConfirmAmpAttachment() {
    confirmationRef.current.open();
  }
  return <>
			<AmpFormSettingsWrapper>
				<Box width='100%' height='100%' display='flex' justifyContent='center' alignItems='center' overflow='hidden' p={1}>
					<AmpFormPreview content={states.content} success={states.success} submit={states.submit} isForm={tab === 'form'} cta_text={states.cta_text} styles={states.styles} theme={states.theme} />
				</Box>

				<ScrollableContainer>
					<Box width='500px'>
						<CustomTabs value={tab} indicatorColor='secondary' textColor='primary' onChange={(evt, value) => setTab(value)}>
							<CustomTab label={<FormattedMessage id='edition_menu.amp_form.modal.form_tab' />} value='form' />
							<CustomTab label={<FormattedMessage id='edition_menu.amp_form.modal.success_tab' />} value='success' />
						</CustomTabs>
					</Box>

					<Box dislay='flex' flexDirection='column' pt={1} textAlign='center' overflow='auto'>
						<Box textAlign='center' pt={2}>
							<div className='properties-wrapper'>
								<div className='property-line'>
									<Typography variant='body1' component='label' className='property-field'>
										<FormattedMessage id='edition_menu.text.font' />
									</Typography>
									<div className='property-content'>
										<FontFamiliesDropdown recentFonts={recentFonts} brandFontFamily={brandFontFamily} onChange={(evt, fontFamily) => handleChangeFormStyle('fontFamily', fontFamily)} value={states?.styles?.fontFamily} fullWidth isOutlined />
									</div>
								</div>
								<div />
							</div>
						</Box>
						<Divider />
						<Typography variant='h4' gutterBottom>
							{intl.messages['edition_menu.form.edit_form.description']}
						</Typography>
						{tab === 'form' && <>
								<DragDropContext onDragEnd={onElementsDragEnd}>
									<Droppable droppableId='droppable'>
										{(provided, snapshot) => <div {...provided.droppableProps} ref={provided.innerRef}>
												{_.map(states.content, (element, index) => <Draggable key={element._id} draggableId={element._id} index={index}>
														{(provided, snapshot) => <ContentElementSettings key={element._id} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} isDragging={snapshot.isDragging} intl={intl} element={element} canDelete={canRemoveField(states.content, element.type)} handleRemoveContentElement={handleRemoveContentElement} currentVariables={currentVariables} currentPage={currentPage} handleChangeElementProperty={handleChangeElementProperty} handleChangeElementStyle={handleChangeElementStyle} setVariables={setVariables} onRecentColorsUpdate={onRecentColorsUpdate} recentColors={recentColors} brandColors={brandColors} recentFonts={recentFonts} brandFontFamily={brandFontFamily} theme={states.theme} />}
													</Draggable>)}
												{provided.placeholder}
											</div>}
									</Droppable>
								</DragDropContext>

								<Box textAlign='center' px={1}>
									<Divider />
									<Typography variant='h4'>
										{intl.messages['edition_menu.form.add_element.description']}
									</Typography>
									<AddNewAmpFieldContainer>
										{_.map(INPUT_SUB_TYPES_LIST, (sub_type, index) => <Box key={index} textAlign='center'>
												<IconButton onClick={() => handleAddContentElement('input', sub_type)} size='large'>
													<SvgIcon>{INPUT_ICONS_LIST[sub_type]}</SvgIcon>
												</IconButton>
												<Typography>
													{intl.messages[`edition_menu.form.field_type.${sub_type}`]}
												</Typography>
											</Box>)}
									</AddNewAmpFieldContainer>
									<AddNewAmpFieldContainer>
										{_.map(CONTENT_SUB_TYPES_LIST, (sub_type, index) => <Box key={index} textAlign='center'>
												<IconButton onClick={() => handleAddContentElement('content', sub_type)} size='large'>
													<SvgIcon>{CONTENT_ICONS_LIST[sub_type]}</SvgIcon>
												</IconButton>
												<Typography>
													{intl.messages[`edition_menu.form.field_type.${sub_type}`]}
												</Typography>
											</Box>)}
									</AddNewAmpFieldContainer>
								</Box>
								<Box textAlign='center' px={1}>
									<Divider />
									<Typography variant='h4'>
										{intl.messages['edition_menu.form.submit_button.description']}
									</Typography>
									<FieldSettingsContainer>
										<div />
										<Box width='100%'>
											<div className='placeholder-container'>
												<Tooltip placement='bottom' title='Submit'>
													<div className='sub-type-icon'>
														<SvgIcon>
															<PublishOutlined />
														</SvgIcon>
													</div>
												</Tooltip>
												<TextField fullWidth variant='outlined' value={states.submit.title} onChange={evt => handleChangeSubmitTitle(evt.target.value)} />
											</div>
											<Box width='100%'>
												<div className='properties-wrapper'>
													<div className='property-line'>
														<Typography variant='body1' component='label' className='property-field'>
															<FormattedMessage id='common.size' />
														</Typography>
														<div className='property-content'>
															<NumberInput initial_value={parseInt(states?.submit?.styles?.fontSize)} onUpdate={value => handleChangeSubmitStyles('fontSize', `${value}px`)} maxValue={450} minValue={6} units='px' inputStep={1} presetValues={[6, 8, 10, 12, 14, 16, 18, 21, 24, 26, 28, 32, 36, 42, 48, 56, 64, 72, 80, 96]} />
														</div>
													</div>
													<div className='property-line'>
														<Typography variant='body1' component='label' className='property-field'>
															<FormattedMessage id='common.radius' />
														</Typography>
														<div className='property-content'>
															<NumberInput initial_value={parseInt(states?.submit?.styles?.borderRadius || '0px')} onUpdate={value => handleChangeSubmitStyles('borderRadius', `${value}px`)} maxValue={100} units='px' inputStep={1} presetValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]} />
														</div>
													</div>
												</div>
												<div className='properties-wrapper'>
													<ColorInput value={states.submit.styles.color} updateValue={value => handleChangeSubmitStyles('color', value)} recentColors={recentColors} brandColors={brandColors} updateRecentColors={onRecentColorsUpdate} />
													<ColorInput value={states.submit.styles.backgroundColor} updateValue={value => handleChangeSubmitStyles('backgroundColor', value)} label={intl.messages['common.background_color']} recentColors={recentColors} brandColors={brandColors} updateRecentColors={onRecentColorsUpdate} />
												</div>
											</Box>
										</Box>
										<div />
									</FieldSettingsContainer>
								</Box>
							</>}

						{tab === 'success' && <>
								<DragDropContext onDragEnd={onSuccessElementsDragEnd}>
									<Droppable droppableId='droppable'>
										{(provided, snapshot) => <div {...provided.droppableProps} ref={provided.innerRef}>
												{_.map(states.success, (element, index) => <Draggable key={element._id} draggableId={element._id} index={index}>
														{(provided, snapshot) => <ContentElementSettings key={element._id} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} isDragging={snapshot.isDragging} intl={intl} element={element} canDelete={true} handleRemoveContentElement={handleRemoveSuccessElement} currentVariables={currentVariables} currentPage={currentPage} handleChangeElementProperty={handleChangeSuccessElementProperty} handleChangeElementStyle={handleChangeSucsessElementStyle} setVariables={setVariables} onRecentColorsUpdate={onRecentColorsUpdate} recentColors={recentColors} brandColors={brandColors} recentFonts={recentFonts} brandFontFamily={brandFontFamily} theme={states.theme} />}
													</Draggable>)}
												{provided.placeholder}
											</div>}
									</Droppable>
								</DragDropContext>

								<Box textAlign='center' px={1}>
									<Divider />
									<Typography variant='h4'>
										{intl.messages['edition_menu.form.add_element.description']}
									</Typography>
									<AddNewAmpFieldContainer>
										{_.map(SUCCESS_SUB_TYPES_LIST, (sub_type, index) => <Box key={index} textAlign='center'>
												<IconButton onClick={() => handleAddSuccessElement('content', sub_type)} size='large'>
													<SvgIcon>{CONTENT_ICONS_LIST[sub_type]}</SvgIcon>
												</IconButton>
												<Typography>
													{intl.messages[`edition_menu.form.field_type.${sub_type}`]}
												</Typography>
											</Box>)}
									</AddNewAmpFieldContainer>
								</Box>
							</>}
					</Box>
				</ScrollableContainer>
			</AmpFormSettingsWrapper>

			<DialogActions>
				{onPreviousStep ? <Button variant='text' color='primary' onClick={onPreviousStep}>
						<FormattedMessage id='button.previous' />
					</Button> : onCancel ? <Button variant='text' color='primary' onClick={onCancel}>
						<FormattedMessage id='button.cancel' />
					</Button> : <div />}

				<Button data-intercom-target='Confirm Button' variant='outlined' color='secondary' onClick={onConfirmAmpAttachment} disabled={isInvalidForm(states?.success)}>
					<FormattedMessage id='common.theming.confirm_button' />
				</Button>
			</DialogActions>

			<ConfirmationModal size='sm' confirmationModalRef={confirmationRef} title={<FormattedMessage id='edition_menu.amp_attachment.save_as_template.title' />} onConfirm={handleSubmit} onCancel={handleSaveAsTemplate} firstLine={<FormattedMessage id='edition_menu.amp_attachment.save_as_template.description' />} confirmText={<FormattedMessage id='modals.amp_attachment.modal.save_only' />} cancelText={<FormattedMessage id='modals.amp_attachment.modal.save_as_template' />} />
		</>;
}
AmpFormSettings.propTypes = {
  onPreviousStep: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmitConfig: PropTypes.func,
  currentForm: PropTypes.object,
  currentPage: PropTypes.object,
  draftStory: PropTypes.object,
  recentColors: PropTypes.array,
  brandColors: PropTypes.array,
  recentFonts: PropTypes.array,
  brandFontFamily: PropTypes.string,
  onRecentColorsUpdate: PropTypes.func
};

// Helper functions

function initAmpForm({
  currentForm,
  variables,
  language
}) {
  if (!_.isEmpty(currentForm?.content)) return currentForm;
  return {
    ...currentForm,
    content: [
    // Title and description
    getAttachmentElement({
      type: 'content',
      sub_type: 'title',
      value: 'Subscribe and save'
    }), getAttachmentElement({
      type: 'content',
      sub_type: 'paragraph',
      value: 'Get 10% off your first purchase by subscribing to our newsletter'
    }),
    // Fields
    initField(language, 'email', variables)]
  };
}
function initField(lang, sub_type, variables) {
  return {
    _id: `amp-${nanoid()}`,
    type: 'input',
    sub_type,
    variable: generateVariableName(sub_type, variables),
    required: true,
    placeholder: translation[`form_field_placeholder_${sub_type}`][lang],
    label: translation[`form_field_label_${sub_type}`][lang]
  };
}
function canRemoveField(content, fieldType) {
  if (fieldType === 'content') {
    return true;
  }
  const fields = _.filter(content, ['type', 'input']) || [];
  return fields.length > 1;
}

// Get variable type via field type
function getVariableType(sub_type) {
  if (sub_type === 'date') return 'date';
  if (sub_type === 'number') return 'number';
  if (sub_type === 'consent') return 'boolean';
  return 'text';
}

// Validate form settings to confirm
function isInvalidForm(success = []) {
  return _.some(success, element => {
    if (element.sub_type === 'button' && _.isEmpty(element?.url)) {
      return true;
    } else if (element.sub_type === 'image' && _.isEmpty(element?.value)) {
      return true;
    } else {
      return false;
    }
  });
}