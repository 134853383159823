import { CogIcon, TrashIcon } from '@heroicons/react/outline';
import { Add, ChevronLeft } from '@mui/icons-material';
import { Box, Button, Chip, List, ListItem, ListItemButton, ListItemText, SvgIcon, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { LimitedFeatureWrapper } from 'atoms';
import { PremiumChip } from 'atoms/Chips';
import Loader from 'components/Loader';
import { ConfirmationModal, ManageTemplateAccessModal } from 'components/Modals';
import { TemplateSectionCategory, TemplatesSection, TemplatesSelectionContainer } from 'components/Modals/StoriesListModals/CreateStoryModal/CreateStoryModal.style';
import SubmitButton from 'components/organisms/buttons/SubmitButton';
import PagePreview from 'components/PagePreview/index';
import { Figure, StickyCheck } from 'components/styled';
import { CustomPageTemplateCard, PageTemplateCard, TEMPLATE_CARD_WIDTH, TemplatesWrapper } from 'components/styled/Modals';
import { NEW_TAGS, TAGS_TEMPLATE } from 'lib/constants';
import { useStoryState } from 'lib/editorStore';
import useSubscriptionFeatures from 'lib/hooks/useSubscriptionFeatures';
import { fetchFromIndexedDB } from 'lib/indexedDB';
import { getFilteredCustomPageTemplates, getFilteredDefaultPageTemplates } from 'lib/utils';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useTransition } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getCustomerRights } from 'redux/customer/selectors';
import { getCurrentOrganizationId } from 'redux/organization/selectors';
import TemplatesSvc from 'services/TemplatesSvc';
import { useImmer } from 'use-immer';
const CUSTOM_TEMPLATE_SECTION = 'custom-templates';
const CURRENT_PAGES_SECTION = 'current-pages';
const PAGE_TEMPLATE_TYPE = ['blank', 'multiple_choice', 'form', 'rating', 'game', 'media_answer'];
const template_tags = [...NEW_TAGS, ...TAGS_TEMPLATE];
const pageTemplateSelectorSlice = s => ({
  page: s.page,
  project: s.draftStory.project,
  theming: s.draftStory.theming,
  messages: s.draftStory.messages,
  gdprConsent: s.draftStory?.gdprConsent,
  storyType: s.draftStory.type,
  pages: s.draftStory.pages
});
export default function PageTemplateSelector({
  onSelect,
  onPreviousStep,
  pageType,
  buttonsType,
  isProcessing,
  isAmp = false,
  isLayoutOnly = false
}) {
  const confirmationRef = useRef();
  const accessModalRef = useRef();
  const [isPending, startTransition] = useTransition({
    timeoutMs: 250
  });
  const intl = useIntl();
  const organizationId = useSelector(getCurrentOrganizationId);
  const currentCustomerRights = useSelector(getCustomerRights);
  const {
    featureSet: {
      custom_templates: hasCustomTemplates
    }
  } = useSubscriptionFeatures();
  const {
    page,
    project,
    theming,
    messages,
    gdprConsent,
    storyType,
    pages
  } = useStoryState(pageTemplateSelectorSlice);
  const [state, setState] = useImmer({
    currentSection: CURRENT_PAGES_SECTION,
    currentLayout: undefined,
    currentLayoutSection: undefined,
    pageTemplatesByTag: {},
    isPageTemplateLoading: true,
    templateToUpdate: {},
    currentType: 'blank'
  });
  useEffect(() => {
    async function fetchPageTemplates() {
      setState(draft => {
        draft.isPageTemplateLoading = true;
      });
      const pageTemplatesByTag = await fetchFromIndexedDB();
      if (pageTemplatesByTag) {
        setState(draft => {
          draft.pageTemplatesByTag = pageTemplatesByTag;
          draft.isPageTemplateLoading = false;
        });
      }
    }
    fetchPageTemplates();
  }, []);
  const setCurrentSection = useCallback(value => setState(draft => {
    draft.currentSection = value;
  }), [setState]);
  const setCurrentType = useCallback(value => startTransition(() => {
    setState(draft => {
      draft.currentType = value;
    });
  }), [setState]);
  const {
    data: myPageTemplates,
    isLoading: isLoadingCustomPageTemplates
  } = useQuery({
    queryKey: ['customPageTemplates', {
      organization_id: organizationId,
      project
    }],
    queryFn: ({
      queryKey
    }) => {
      if (!hasCustomTemplates) return Promise.resolve([]);
      const {
        project,
        organization_id
      } = queryKey[1];
      return TemplatesSvc.getPageTemplates({
        project,
        organization_id
      });
    }
  });
  const queryClient = useQueryClient();
  const updateCustomPageTemplateMutation = useMutation({
    mutationFn: TemplatesSvc.updatePageTemplate,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['customPageTemplates']
      });
    }
  });
  const deleteCustomPageTemplateMutation = useMutation({
    mutationFn: TemplatesSvc.removePageTemplate,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['customPageTemplates']
      });
      setState(draft => {
        // If removed template is chosen then reset currentLayout
        if (draft.currentLayout?._id === draft.templateToUpdate?.page?._id) {
          draft.currentLayout = undefined;
        }
        draft.templateToUpdate = {};
      });
      confirmationRef.current.close();
    }
  });
  const filteredCurrentPages = isLayoutOnly ? _.filter(pages, pageTemplate => pageTemplate._id !== page?._id && !_.includes(['start_page', 'ending_page'], pageTemplate.type)) : _.filter(pages, pageTemplate => !_.includes(['start_page', 'ending_page'], pageTemplate.type));
  const filteredDefaultPageTemplates = getFilteredDefaultPageTemplates({
    pageTemplatesByTag: state.pageTemplatesByTag,
    type: pageType || page.type,
    buttonsType: buttonsType || page?.answers_properties?.type,
    currentType: state.currentType
  });
  const filteredCustomPageTemplates = getFilteredCustomPageTemplates({
    pageTemplates: myPageTemplates,
    type: pageType || page.type,
    buttonsType: buttonsType || page?.answers_properties?.type,
    currentType: state.currentType,
    isLayoutOnly
  }).reverse();
  async function handleLayoutSelection(layout, section) {
    if (layout !== state.currentLayout) {
      setState(draft => {
        draft.currentLayout = layout;
        draft.currentLayoutSection = section;
      });
    } else if (storyType !== 'amp' && !isProcessing) {
      onSelect(state.currentLayout);
    }
  }
  function openConfirmationModal(template) {
    setState(draft => {
      draft.templateToUpdate = template;
    });
    confirmationRef.current.open();
  }
  function openAccessModal(template) {
    setState(draft => {
      draft.templateToUpdate = template;
    });
    accessModalRef.current.open();
  }

  // Handle Change Project Access
  function handleProjectAccessChange(project_id) {
    if (!_.includes(state.templateToUpdate.accessibleProjects, project_id)) {
      setState(draft => {
        draft.templateToUpdate.accessibleProjects.push(project_id);
      });
    } else {
      setState(draft => {
        draft.templateToUpdate.accessibleProjects = draft.templateToUpdate.accessibleProjects.filter(_id => _id !== project_id);
      });
    }
  }
  const onSectionClick = useCallback(sectionId => () => {
    setCurrentSection(sectionId);
    const sectionNode = document.getElementById(`waypoint-${sectionId}`);
    if (sectionNode) {
      sectionNode.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  }, [setCurrentSection]);
  function isSelected(templateId, section) {
    return state.currentLayout?._id === templateId && state.currentLayoutSection === section;
  }
  function renderTemplateSection() {
    const relevantTemplates = filteredDefaultPageTemplates[state.currentSection];
    if (state.currentSection === CURRENT_PAGES_SECTION) {
      return <Box>
					<TemplateSectionCategory>
						{intl.messages['modals.templates.tabs.current_pages_select']}
					</TemplateSectionCategory>
					<TemplatesWrapper>
						{_.map(filteredCurrentPages, (template, templateIndex) => <PageTemplateCard key={template._id} $isActive={isSelected(template._id, state.currentLayoutSection)} onClick={() => handleLayoutSelection(template, state.currentLayoutSection)}>
								{isSelected(template._id, state.currentLayoutSection) && <StickyCheck />}
								<PagePreview page={generatePageTemplate({
              currentPage: page,
              template,
              isLayoutOnly
            })} theming={theming} messages={messages} gdprConsent={gdprConsent} storyType={storyType} size={TEMPLATE_CARD_WIDTH} />

								<figcaption>{template.name}</figcaption>
							</PageTemplateCard>)}
					</TemplatesWrapper>
				</Box>;
    }
    if (state.currentSection === CUSTOM_TEMPLATE_SECTION) {
      return <Box>
					<TemplateSectionCategory>
						{intl.messages['modals.templates.tabs.custom']}
						<Box ml={1}>
							<PremiumChip size='medium' />
						</Box>
					</TemplateSectionCategory>

					<TemplatesWrapper>
						{isLoadingCustomPageTemplates ? <Loader $heightPx={100} size={50} name='ThreeBounce' /> : _.isEmpty(filteredCustomPageTemplates) ? <Figure height={100}>
								<img src='/static/placeholders/empty_folder.svg' />
								<figcaption>
									<FormattedMessage id='modals.templates.page.empty' />
								</figcaption>
							</Figure> : _.map(filteredCustomPageTemplates, template => <CustomPageTemplateCard key={template._id} $isActive={isSelected(template.page._id, CUSTOM_TEMPLATE_SECTION)}>
									{isSelected(template.page._id, CUSTOM_TEMPLATE_SECTION) && <StickyCheck />}

									<div className='cover'>
										<Button variant='contained' color='primary' size='large' onClick={() => handleLayoutSelection(template.page, CUSTOM_TEMPLATE_SECTION)}>
											{intl.messages['modals.templates.button.select']}
										</Button>

										{currentCustomerRights.isAdmin && <Box bgcolor={'white'}>
												<Button variant='outlined' size='small' onClick={() => openAccessModal(template)} startIcon={<SvgIcon>
															<CogIcon />
														</SvgIcon>}>
													{intl.messages['modals.templates.button.manage']}
												</Button>
											</Box>}
										<Box bgcolor={'white'}>
											<Button color='error' variant='contained' size='small' onClick={() => openConfirmationModal(template)} startIcon={<SvgIcon>
														<TrashIcon />
													</SvgIcon>}>
												{intl.messages['button.delete']}
											</Button>
										</Box>
									</div>

									<PagePreview page={generatePageTemplate({
              currentPage: page,
              template: template.page,
              isLayoutOnly
            })} theming={theming} messages={messages} gdprConsent={gdprConsent} storyType={storyType} size={TEMPLATE_CARD_WIDTH} />

									<figcaption>{template.name}</figcaption>
								</CustomPageTemplateCard>)}
					</TemplatesWrapper>
				</Box>;
    }
    if (_.isEmpty(relevantTemplates)) {
      return null;
    }
    return <Box>
				<TemplateSectionCategory>
					{getTagLabel({
          intl,
          tag: state.currentLayoutSection
        })}
				</TemplateSectionCategory>

				<TemplatesWrapper>
					{_.map(relevantTemplates, (template, templateIndex) => <PageTemplateCard key={template._id} $isActive={isSelected(template._id, state.currentLayoutSection)} onClick={() => handleLayoutSelection(template, state.currentLayoutSection)}>
							{isSelected(template._id, state.currentLayoutSection) && <StickyCheck />}
							<PagePreview page={generatePageTemplate({
            currentPage: page,
            template,
            isLayoutOnly
          })} theming={theming} messages={messages} gdprConsent={gdprConsent} storyType={storyType} size={TEMPLATE_CARD_WIDTH} />

							<figcaption>{template.name}</figcaption>
						</PageTemplateCard>)}
				</TemplatesWrapper>
			</Box>;
  }
  return <>
			<TemplatesSelectionContainer>
				<List component='nav' disablePadding>
					<ListItem divider disablePadding>
						<ListItemButton selected={state.currentSection === CURRENT_PAGES_SECTION} onClick={onSectionClick(CURRENT_PAGES_SECTION)} sx={{
            px: 2,
            py: 1
          }}>
							<ListItemText primary={<>
										{intl.messages['modals.templates.tabs.current_pages']}{' '}
										{`(${filteredCurrentPages.length})`}
									</>} />
						</ListItemButton>
					</ListItem>
					<LimitedFeatureWrapper disabled={!hasCustomTemplates}>
						<ListItem divider disablePadding>
							<ListItemButton selected={state.currentSection === CUSTOM_TEMPLATE_SECTION} onClick={onSectionClick(CUSTOM_TEMPLATE_SECTION)} sx={{
              px: 2,
              py: 1
            }}>
								<ListItemText primary={<>
											{intl.messages['modals.templates.tabs.custom']}{' '}
											{`(${filteredCustomPageTemplates.length})`}
										</>} />
								<PremiumChip />
							</ListItemButton>
						</ListItem>
					</LimitedFeatureWrapper>

					{state.isPageTemplateLoading ? <Loader $heightPx={100} size={40} name='ThreeBounce' /> : template_tags.map((tag, index) => {
          const tagCount = filteredDefaultPageTemplates[tag]?.length;
          return tagCount > 0 ? <ListItem key={index} disablePadding>
									<ListItemButton selected={state.currentSection === tag} onClick={onSectionClick(tag)} sx={{
              px: 2,
              py: 1
            }}>
										<ListItemText primaryTypographyProps={{
                sx: {
                  fontWeight: NEW_TAGS.includes(tag) ? 'bold' : 'normal'
                }
              }} primary={getTagLabel({
                intl,
                tag,
                tagCount
              })} />
									</ListItemButton>
								</ListItem> : null;
        })}
				</List>

				<div>
					{pageType === 'all' && state.currentSection !== CURRENT_PAGES_SECTION && <Box width='100%' display='flex' flexDirection='row' alignItems='center' gap={1} mb={2} pl={2}>
							{_.map(PAGE_TEMPLATE_TYPE, (type, index) => <Chip key={index} label={intl.messages[`editor.configuration.page_type.${type}`]} variant={state.currentType === type ? 'filled' : 'outlined'} color='primary' onClick={() => setCurrentType(type)} />)}
						</Box>}
					<TemplatesSection>
						{state.isPageTemplateLoading || isLoadingCustomPageTemplates ? <Loader $heightPx={100} size={50} name='ThreeBounce' /> : renderTemplateSection()}
					</TemplatesSection>
				</div>
			</TemplatesSelectionContainer>

			<ConfirmationModal size='sm' confirmationModalRef={confirmationRef} title={<FormattedMessage id='modals.templates.delete.title' />} onConfirm={() => deleteCustomPageTemplateMutation.mutate({
      project,
      organization_id: organizationId,
      pageTemplate_id: state.templateToUpdate?._id
    })} firstLine={<FormattedMessage id='modal.file_deletion.confirmation.sub_header' />} secondLine={<FormattedMessage id='modal.file_deletion.confirmation.instructions' />} confirmText={<FormattedMessage id='common.yes' />} cancelText={<FormattedMessage id='common.no' />} />
			<ManageTemplateAccessModal ref={accessModalRef} templateToUpdate={state.templateToUpdate} onProjectAccessChange={handleProjectAccessChange} onConfirm={() => updateCustomPageTemplateMutation.mutate({
      project,
      organization_id: organizationId,
      pageTemplate: state.templateToUpdate
    })} />

			<Box p={2} display='flex' alignItems='center' justifyContent='space-between'>
				{onPreviousStep && !isAmp ? <Button variant='text' onClick={onPreviousStep} startIcon={<ChevronLeft />}>
						<FormattedMessage id='button.previous' />
					</Button> : <div />}

				<Box display='grid' gap={1.5} gridAutoFlow={'column'} alignItems='center'>
					{/* CREATE FROM SCRATCH */}
					{!isLayoutOnly && <>
							<Button variant='outlined' startIcon={<Add />} onClick={() => onSelect(undefined)} data-intercom-target='From Scratch'>
								<FormattedMessage id='modals.create_snackeet.steps.from_scratch' />
							</Button>

							<Typography variant='h4'>
								<FormattedMessage id='modals.create_snackeet.steps.create_with' />
							</Typography>
						</>}

					<SubmitButton data-intercom-target='Confirm Button' variant='contained' loading={isProcessing} disabled={!state.currentLayout || isProcessing} onClick={() => {
          if (!state.currentLayout || isProcessing) return;
          onSelect(state.currentLayout);
        }}>
						<FormattedMessage id='common.theming.confirm_button' />
					</SubmitButton>
				</Box>
			</Box>
		</>;
}
PageTemplateSelector.propTypes = {
  onSelect: PropTypes.func,
  onPreviousStep: PropTypes.func,
  pageType: PropTypes.string,
  buttonsType: PropTypes.string,
  isAmp: PropTypes.bool,
  isProcessing: PropTypes.bool,
  isLayoutOnly: PropTypes.bool
};
function generatePageTemplate({
  currentPage,
  template,
  isLayoutOnly = false
}) {
  if (isLayoutOnly) {
    const refactorCurrentPage = {
      type: 'blank',
      ..._.pick(template, ['background_element', 'blocks', 'tags', 'carousels', 'buttonLists'])
    };
    return refactorCurrentPage;
  }
  return template;
}
function getTagLabel({
  intl,
  tag,
  tagCount
}) {
  let label = intl.messages[`common.story_tag.${tag}`];
  if (tagCount) {
    label += ` (${tagCount})`;
  }
  return label;
}