import { css } from '@emotion/react';
import styled from '@styled';
export const ProductContainer = styled.div`
	position: absolute;
	pointer-events: auto;

	width: 100%;
	padding-top: 8px;
	overflow: auto;

	&::-webkit-scrollbar {
		display: none;
	}

	&:hover {
		outline: ${({
  theme
}) => `1px solid ${theme.palette.primary.main}`};
	}
`;
export const ProductSlider = styled.div`
	width: 100%;
	overflow: auto;

	&::-webkit-scrollbar {
		display: none;
	}
`;
export const ProductWrapper = styled.div`
	width: ${({
  $isCenter
}) => $isCenter ? '100%' : 'max-content'};
	overflow: auto;
	pointer-events: auto;
	cursor: inherit;
	display: flex;
	align-items: stretch;
	justify-content: ${({
  $isCenter
}) => $isCenter ? 'center' : 'flex-start'};

	gap: 16px;
	max-height: 100%;
	margin: 0;
	padding: ${({
  theme
}) => theme.spacing(1, 2)};
`;
const roundedCardStyle = ({
  $shape
}) => $shape === 'rounded' && css`
		border-radius: 8px;

		.img-container {
			border-radius: 8px;
		}

		.btn {
			border-radius: 8px;
		}
	`;
const rectangleCardStyle = ({
  $shape
}) => $shape === 'rectangle' && css`
		border-radius: 0px;

		.img-container {
			border-radius: 0px;
		}

		.btn {
			border-radius: 0px;
		}
	`;
const pillCardStyle = ({
  $shape
}) => $shape === 'pill' && css`
		border-radius: 60px;
		padding-right: 16px;

		.img-container {
			border-radius: 60px;
		}

		.btn {
			border-radius: 60px;
		}
	`;
export const ProductCard = styled.div`
	padding: 8px;
	overflow: hidden;
	display: grid;
	grid-template-columns: 80px 1fr;
	align-items: center;
	width: 310px;

	gap: 8px;
	backdrop-filter: blur(5px);
	color: #000;

	cursor: pointer;

	.img-container {
		width: 80px;
		height: 80px;
		aspect-ratio: 1 / 1;
		overflow: hidden;
		position: relative;
		backdrop-filter: none;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.detailed-title {
		width: 100%;
		padding: 0px 4px;
		font-size: inherit;
		backdrop-filter: none;

		.title {
			display: -webkit-box;
			max-height: 2.8em;
			width: 100%;
			font-size: 11px;
			line-height: 1.4em;
			word-wrap: break-word;
			overflow: hidden;
			font-weight: 700;
			text-overflow: ellipsis;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}

		.description {
			display: -webkit-box;
			max-height: 2.8em;
			width: 100%;
			font-size: 8px;
			line-height: 1.4em;
			word-wrap: break-word;
			overflow: hidden;
			text-overflow: ellipsis;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			margin-top: 3px;
		}

		.price {
			display: -webkit-box;
			height: 1.4em;
			width: 100%;
			font-size: 10px;
			line-height: 1.4em;
			word-wrap: break-word;
			overflow: hidden;
			text-overflow: ellipsis;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			margin-top: 3px;
		}

		.btn {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 4px;

			background-color: #000;
			color: #fff;
			padding: 5px 8px;
			font-size: 10px;
			font-weight: bold;
			margin: 4px auto 0px;
			text-align: center;

			svg {
				font-size: 13px;
			}
		}
	}
	${roundedCardStyle}
	${rectangleCardStyle}
	${pillCardStyle}
`;