import { ChevronLeft, ChevronRight, ShoppingBagOutlined, ShoppingBasketOutlined, ShoppingCartOutlined, StorefrontOutlined, VisibilityOutlined } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import Animate from 'components/Animate';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ProductCard, ProductContainer, ProductSlider, ProductWrapper } from './styled';
export const SHOPIFY_BUTTON_ICONS = {
  view: <VisibilityOutlined fontSize='inherit' />,
  shopping_bag: <ShoppingBagOutlined fontSize='inherit' />,
  shopping_cart: <ShoppingCartOutlined fontSize='inherit' />,
  storefront: <StorefrontOutlined fontSize='inherit' />,
  shopping_basket: <ShoppingBasketOutlined fontSize='inherit' />
};
export default function ShopifyProduct({
  shopifyProduct
}) {
  const {
    _id,
    items,
    outer_style,
    title,
    inner_style,
    settings,
    animation
  } = shopifyProduct;
  const refactoredAnimation = {
    name: animation?.name || '',
    duration: animation?.duration || 1000,
    delay: animation?.delay || 0,
    forever: false,
    displayDelay: animation?.displayDelay || 0
  };
  const itemStyles = _.pick(inner_style, ['fontSize', 'backgroundColor', 'color', 'borderColor', 'borderStyle', 'borderRadius', 'borderWidth']);
  const doNotDisplayNavigation = items?.length <= 1;
  const navigationPos = (80 + 16 + 2) / 2 - 4;
  function renderProductCard(card, index) {
    return <ProductCard key={card._id} $shape={inner_style?.shape} style={itemStyles}>
				<div className='img-container'>
					<img src={card.image || '/static/images/placeholder_img.png'} alt={card.title} />
				</div>

				<div className='detailed-title'>
					<span className='title'>{card.title}</span>
					<div style={{
          minHeight: '30px'
        }}>
						{settings.displayDescription && <em className='description'>{card.description}</em>}
						{settings.displayPrice && <span className='price'>
								{card.price} {card.currency}
							</span>}
					</div>
					{settings?.btn_title && <div className='btn' style={{
          color: inner_style?.btn_color,
          backgroundColor: inner_style?.btn_backgroundColor,
          borderRadius: inner_style?.borderRadius
        }}>
							{SHOPIFY_BUTTON_ICONS[settings.icon]}
							{settings.btn_title}
						</div>}
				</div>
			</ProductCard>;
  }
  return <ProductContainer style={outer_style}>
			<Animate animation={refactoredAnimation} sx={{
      height: '100%',
      width: '100%'
    }}>
				<Box width='100%' display='flex' justifyContent='space-between' alignItems='center' px={1}>
					<Typography sx={{
          fontFamily: 'inherit',
          fontSize: '1em'
        }}>{title}</Typography>
				</Box>
				<Box width='100%' height='max-content' sx={{
        position: 'relative'
      }}>
					<ProductSlider>
						<ProductWrapper $isCenter={doNotDisplayNavigation} $isStretch={false}>
							{items.map((card, index) => renderProductCard(card, index))}
						</ProductWrapper>
					</ProductSlider>

					{!doNotDisplayNavigation && <>
							<Box width='26px' height='26px' display='flex' justifyContent='center' alignItems='center' sx={{
            border: '1px solid #000',
            borderRadius: '50%',
            position: 'absolute',
            top: `${navigationPos}px`,
            left: '4px',
            backgroundColor: 'white',
            overflow: 'hidden',
            zIndex: '15'
          }}>
								<IconButton size='small' sx={{
              color: '#000'
            }}>
									<ChevronLeft fontSize='medium' />
								</IconButton>
							</Box>
							<Box width='26px' height='26px' display='flex' justifyContent='center' alignItems='center' sx={{
            border: '1px solid #000',
            borderRadius: '50%',
            position: 'absolute',
            top: `${navigationPos}px`,
            right: '4px',
            backgroundColor: 'white',
            overflow: 'hidden',
            zIndex: '15'
          }}>
								<IconButton size='small' sx={{
              color: '#000'
            }}>
									<ChevronRight fontSize='medium' />
								</IconButton>
							</Box>
						</>}
				</Box>
			</Animate>
		</ProductContainer>;
}
ShopifyProduct.propTypes = {
  shopifyProduct: PropTypes.object
};