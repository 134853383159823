import { CodeIcon } from '@heroicons/react/outline';
import { ErrorOutlineOutlined, ImageOutlined, LinkOutlined, TitleOutlined, ViewHeadlineOutlined } from '@mui/icons-material';
import { Box, Button, DialogActions, Divider, IconButton, SvgIcon, Typography } from '@mui/material';
import { ConfirmationModal } from 'components/Modals';
import FontFamiliesDropdown from 'components/organisms/FontFamiliesDropdown';
import { AddNewAmpFieldContainer, AmpFormSettingsWrapper } from 'components/styled/Modals';
import { getAttachmentElement } from 'lib/models';
import { isUrl, reorder } from 'lib/utils';
import _ from 'lodash';
import Hotspot from 'organisms/Hotspot';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getCurrentOrganizationId } from 'redux/organization/selectors';
import TemplatesSvc from 'services/TemplatesSvc';
import { useImmer } from 'use-immer';
import AmpAttachmentPreview from './AmpAttachmentPreview';
import ContentElementSettings from './ContentElementSettings';
const content_SUB_TYPES_LIST = ['title', 'paragraph', 'button', 'image', 'iframe'];
const CONTENT_ICONS_LIST = {
  title: <TitleOutlined />,
  paragraph: <ViewHeadlineOutlined />,
  disclaimer: <ErrorOutlineOutlined />,
  image: <ImageOutlined />,
  button: <LinkOutlined />,
  iframe: <CodeIcon />
};

// States structure
/*
	_id: `amp-${nanoid}`,
		cta_text: <String>,
		theme: <Sring>,
		styles: <Object>,
		content: [<AttachmentElement>[content]],
*/

export default function AmpAttchmentSettings({
  onPreviousStep,
  onCancel,
  onSubmitConfig,
  currentAttachment,
  currentPage,
  draftStory,
  recentColors,
  brandColors,
  recentFonts,
  brandFontFamily,
  onRecentColorsUpdate
}) {
  const intl = useIntl();
  const confirmationRef = useRef();
  const [states, setStates] = useImmer(currentAttachment);
  const organizationId = useSelector(getCurrentOrganizationId);
  function handleAddElement(type, sub_type) {
    const newElement = getAttachmentElement({
      type,
      sub_type
    });
    setStates(draft => {
      draft.content.push(newElement);
    });
  }
  function handleRemoveElement(_id) {
    const index = _.findIndex(states.content, field => field._id === _id);
    if (index > -1) {
      setStates(draft => {
        draft.content.splice(index, 1);
      });
    }
  }
  function onElementsDragEnd(result) {
    if (!result.destination) {
      return;
    }
    setStates(draft => {
      draft.content = reorder(draft.content, result.source.index, result.destination.index);
    });
  }
  function handleChangeAttachmentStyle(path, value) {
    setStates(draft => {
      draft.styles[path] = value;
    });
  }
  function handleChangeElementProperty(_id, path, value) {
    const idx = _.findIndex(states.content, element => element._id === _id);
    setStates(draft => {
      draft.content[idx][path] = value;
    });
  }
  function handleChangeElementStyle(_id, path, value) {
    const idx = _.findIndex(states.content, element => element._id === _id);
    setStates(draft => {
      draft.content[idx].styles[path] = value;
    });
  }
  function handleSubmit() {
    onSubmitConfig(states);
  }
  async function handleSaveAsTemplate() {
    await TemplatesSvc.saveAttachmentAsTemplate({
      attachment: states,
      name: 'Attachment',
      accessibleProjects: [draftStory.project],
      project: draftStory.project,
      organization_id: organizationId
    });
    handleSubmit();
  }
  function onConfirmAmpAttachment() {
    confirmationRef.current.open();
  }
  return <>
			<AmpFormSettingsWrapper>
				<Box width='100%' height='100%' display='flex' justifyContent='center' alignItems='center' overflow='hidden' p={1}>
					<AmpAttachmentPreview content={states.content} cta_text={states.cta_text} styles={states.styles} theme={states.theme} />
				</Box>

				<Box>
					<Box dislay='flex' flexDirection='column' pt={1} textAlign='center' overflow='auto'>
						<Box textAlign='center' pt={2}>
							<div className='properties-wrapper'>
								<div className='property-line'>
									<Typography variant='body1' component='label' className='property-field'>
										<FormattedMessage id='edition_menu.text.font' />
									</Typography>
									<div className='property-content'>
										<FontFamiliesDropdown recentFonts={recentFonts} brandFontFamily={brandFontFamily} onChange={(evt, fontFamily) => handleChangeAttachmentStyle('fontFamily', fontFamily)} value={states?.styles?.fontFamily} fullWidth isOutlined />
									</div>
								</div>
								<div />
							</div>
						</Box>
						<Divider />
						<Typography variant='h4' gutterBottom>
							{intl.messages['edition_menu.attachement.edit_content.description']}
						</Typography>
						<DragDropContext onDragEnd={onElementsDragEnd}>
							<Droppable droppableId='droppable'>
								{(provided, snapshot) => <div {...provided.droppableProps} ref={provided.innerRef}>
										{_.map(states.content, (element, index) => <Draggable key={element._id} draggableId={element._id} index={index}>
												{(provided, snapshot) => <ContentElementSettings key={element._id} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} isDragging={snapshot.isDragging} intl={intl} element={element} canDelete={true} handleRemoveContentElement={handleRemoveElement} currentPage={currentPage} handleChangeElementProperty={handleChangeElementProperty} handleChangeElementStyle={handleChangeElementStyle} onRecentColorsUpdate={onRecentColorsUpdate} recentColors={recentColors} brandColors={brandColors} recentFonts={recentFonts} brandFontFamily={brandFontFamily} theme={states.theme} />}
											</Draggable>)}
										{provided.placeholder}
									</div>}
							</Droppable>
						</DragDropContext>

						<Box textAlign='center' px={1}>
							<Divider />
							<Typography variant='h4'>
								<FormattedMessage id='edition_menu.form.add_element.description' />
							</Typography>
							<AddNewAmpFieldContainer>
								{_.map(content_SUB_TYPES_LIST, (sub_type, index) => <Box key={index} textAlign='center'>
										<IconButton onClick={() => handleAddElement('content', sub_type)} size='large'>
											<SvgIcon>{CONTENT_ICONS_LIST[sub_type]}</SvgIcon>
										</IconButton>
										<Box display='flex' justifyContent='center' alignItems='center'>
											<Typography>
												<FormattedMessage id={`edition_menu.form.field_type.${sub_type}`} />
											</Typography>

											{sub_type === 'iframe' && <Hotspot IconProps={{
                    fontSize: 'small',
                    sx: {
                      ml: 1
                    }
                  }} message={'edition_menu.amp_attachment.iframe_warning'} />}
										</Box>
									</Box>)}
							</AddNewAmpFieldContainer>
						</Box>
					</Box>
				</Box>
			</AmpFormSettingsWrapper>

			<DialogActions>
				{onPreviousStep ? <Button variant='text' color='primary' onClick={onPreviousStep}>
						<FormattedMessage id='button.previous' />
					</Button> : onCancel ? <Button variant='text' color='primary' onClick={onCancel}>
						<FormattedMessage id='button.cancel' />
					</Button> : <div />}

				<Button data-intercom-target='Confirm Button' variant='outlined' color='secondary' onClick={onConfirmAmpAttachment} disabled={isInvalidAttachment(states?.content)}>
					<FormattedMessage id='common.theming.confirm_button' />
				</Button>
			</DialogActions>

			<ConfirmationModal size='sm' confirmationModalRef={confirmationRef} title={<FormattedMessage id='edition_menu.amp_attachment.save_as_template.title' />} onConfirm={handleSubmit} onCancel={handleSaveAsTemplate} firstLine={<FormattedMessage id='edition_menu.amp_attachment.save_as_template.description' />} confirmText={<FormattedMessage id='modals.amp_attachment.modal.save_only' />} cancelText={<FormattedMessage id='modals.amp_attachment.modal.save_as_template' />} />
		</>;
}
AmpAttchmentSettings.propTypes = {
  onPreviousStep: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmitConfig: PropTypes.func,
  currentForm: PropTypes.object,
  currentPage: PropTypes.object,
  draftStory: PropTypes.object,
  recentColors: PropTypes.array,
  brandColors: PropTypes.array,
  recentFonts: PropTypes.array,
  brandFontFamily: PropTypes.string,
  onRecentColorsUpdate: PropTypes.func
};

// Helper functions

// Validate form settings to confirm
function isInvalidAttachment(content = []) {
  return _.some(content, element => {
    if (element.sub_type === 'button' && (_.isEmpty(element?.url) || !isUrl(element.url)?.success)) {
      return true;
    } else if (element.sub_type === 'image' && _.isEmpty(element?.value)) {
      return true;
    } else {
      return false;
    }
  });
}