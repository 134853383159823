import styled from '@styled';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ShopifyProduct from './ShopifyProduct';
export default function ShopifyProducts({
  page
}) {
  return page?.shopifyProducts && !_.isEmpty(page.shopifyProducts) && <TagsContainer>
				{page.shopifyProducts.map(shopifyProduct => <ShopifyProduct key={shopifyProduct._id} shopifyProduct={shopifyProduct} />)}
			</TagsContainer>;
}
ShopifyProducts.propTypes = {
  page: PropTypes.object
};
const TagsContainer = styled.div`
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	box-sizing: border-box;
	pointer-events: none;
`;