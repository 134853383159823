import { block as _block$ } from "million/react-server";
import { TrashIcon } from '@heroicons/react/outline';
import { GridOnOutlined, OpenInNew, Settings, WebStoriesOutlined } from '@mui/icons-material';
import { Box, Button, IconButton, SvgIcon, Tooltip } from '@mui/material';
import cx from 'classnames';
import { CustomIcon } from 'components/icons';
import Loader from 'components/Loader';
import { Figure } from 'components/styled';
import { MediaAssetCard, MediaAssetIconButton } from 'containers/Editor/StoryEditor/MediaAssets/Galleries/MediaComponent/MediaComponent.style';
import { EventEmitter } from 'lib/EventEmitter';
import { playVideo } from 'lib/utils/mediaUtils';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import MultiRef from 'react-multi-ref';
import InstagramSvc from 'services/InstagramSvc';
import { useImmer } from 'use-immer';
import AccountsMenu from './AccountsMenu';
import { InfiniteMasonry, itemIndexes } from './InfiniteMasonry';
import { InstagramTab, InstagramTabs, SelectedCountContainer, SelectedIndexLabel, StoryLimitationsList, ValidateButton, ViewHeader } from './InstagramAssets.styles';
const tabIcons = {
  posts: <GridOnOutlined />,
  stories: <WebStoriesOutlined />,
  reels: <CustomIcon name='InstagramReelsIcon' />
};
const M$MediaView = function MediaView({
  assetsTypes,
  mediaTypes,
  selectedAccount,
  isMultipleSelect,
  goToView,
  onAccountSelect,
  onAssetSelect
}) {
  // Use MultiRef to handle array of container refs for masonry
  const [containersRef] = useState(() => new MultiRef());
  const [state, setState] = useImmer(loadInitialState(assetsTypes));
  const onTabChange = useCallback((evt, tab) => {
    setState(draft => {
      draft.tab = tab;
      draft.selectedIds = [];
    });
  }, [setState]);
  const loadInitialMedia = useCallback(async account => {
    setState(draft => {
      draft.isLoading = true;
    });
    const assetsResponse = await InstagramSvc.getAssets({
      account,
      assetsTypes,
      mediaTypes
    });
    itemIndexes.reset();
    setState(draft => {
      draft.isLoading = false;
      _.each(assetsResponse, ({
        assetsType,
        assets,
        paging,
        error
      }) => {
        draft[assetsType] = {
          assets,
          paging,
          error
        };
      });
    });
  }, [assetsTypes, mediaTypes, setState]);
  const loadMore = useCallback((assetsType, next) => async (startIndex, endIndex) => {
    const isLoaded = itemIndexes.isLoaded({
      type: assetsType,
      startIndex,
      endIndex
    });
    if (isLoaded || !next) return;
    itemIndexes.load({
      type: assetsType,
      startIndex,
      endIndex
    });
    const {
      assets,
      paging
    } = await InstagramSvc.getAssetsByType({
      account: selectedAccount,
      assetsType,
      mediaTypes,
      next
    });
    setState(draft => {
      draft[assetsType].assets = [...draft[assetsType].assets, ...assets];
      draft[assetsType].paging = paging;
    });
  }, [mediaTypes, selectedAccount, setState]);
  const onSelect = useCallback(evtData => {
    if (isMultipleSelect) {
      const {
        id
      } = evtData;
      setState(draft => {
        const index = _.indexOf(draft.selectedIds, id);
        if (index === -1) {
          draft.selectedIds.push(id);
        } else {
          draft.selectedIds.splice(index, 1);
        }
      });
    } else {
      onAssetSelect(evtData);
    }
  }, [isMultipleSelect, onAssetSelect, setState]);
  const clearSelection = useCallback(() => {
    setState(draft => {
      draft.selectedIds = [];
    });
  }, [setState]);
  const confirmSelection = useCallback(() => {
    const selectedElements = _.filter(state[state.tab].assets, asset => state.selectedIds.includes(asset.id));
    onAssetSelect(selectedElements);
  }, [onAssetSelect, state]);
  useEffect(() => {
    loadInitialMedia(selectedAccount);
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [loadInitialMedia, selectedAccount]);
  useEffect(() => {
    setState(draft => loadInitialState(assetsTypes));
    itemIndexes.reset();
  }, [assetsTypes, setState]);
  useEffect(() => {
    EventEmitter.subscribe('ON_ASSET_SELECT', onSelect);
    return () => {
      EventEmitter.unsubscribe('ON_ASSET_SELECT', onSelect);
    };
  }, [onSelect]);
  return <>
			<ViewHeader>
				{selectedAccount && <AccountsMenu selectedAccount={selectedAccount} onAccountSelect={onAccountSelect} goToView={goToView} />}
				<Box margin='auto' gridArea='title'>
					<InstagramTabs centered value={state.tab} onChange={onTabChange}>
						{_.map(assetsTypes, assetsType => <InstagramTab key={`tab-${assetsType}`} value={assetsType} label={_.capitalize(assetsType)} disableRipple iconPosition='start' icon={tabIcons[assetsType]} />)}
					</InstagramTabs>
				</Box>

				{isMultipleSelect && <SelectedCount count={state.selectedIds.length} clearSelection={clearSelection} confirmSelection={confirmSelection} />}
			</ViewHeader>

			{_.map(assetsTypes, assetsType => <div key={`tabpanel-${assetsType}`} id={`tabpanel-${assetsType}`} aria-labelledby={`simple-tab-${assetsType}`} role='tabpanel' hidden={state.tab !== assetsType} ref={containersRef.ref(assetsType)}>
					{state.tab === assetsType && <MediaAssetsTab key={`tab-${assetsType}-${selectedAccount?.pageId}`} assetsType={assetsType} assets={state[assetsType].assets} selectedIds={state.selectedIds} containerNode={containersRef.map.get(assetsType)} isLoading={state.isLoading} error={state[assetsType].error} loadMore={loadMore(assetsType, state[assetsType].paging?.next)} goToView={goToView} />}
				</div>)}
		</>;
};
const MediaView = M$MediaView;
export default MediaView;
MediaView.propTypes = {
  assetsTypes: PropTypes.array,
  mediaTypes: PropTypes.array,
  selectedAccount: PropTypes.object,
  isMultipleSelect: PropTypes.bool,
  goToView: PropTypes.func,
  onAccountSelect: PropTypes.func,
  onAssetSelect: PropTypes.func
};
function MediaAssetsTab({
  containerNode,
  assets,
  assetsType,
  selectedIds,
  isLoading,
  error,
  loadMore,
  goToView
}) {
  const MediaItemWithContext = useCallback(props => <MediaItem {...props} selectedIndex={_.indexOf(selectedIds, props.data.id)} onClick={() => EventEmitter.dispatch('ON_ASSET_SELECT', {
    index: props.index,
    ...props.data
  })} />, [selectedIds]);
  if (isLoading) {
    return <Loader $heightPx={400} size={100} />;
  }
  if (error) {
    return <Box display='flex' justifyContent='center' width='100%' height='100%'>
				<Figure height={250}>
					<img src={error.code === 10 ? '/static/placeholders/validation.svg' : '/static/placeholders/error.svg'} />
					<figcaption>
						<FormattedMessage id='integrations.instagram.media_view.error.permission.title' />
					</figcaption>
					<Box fontSize={16} mt={1}>
						<FormattedMessage id='integrations.instagram.media_view.error.permission.description' />
					</Box>
					<Box width='fit-content' mt={2}>
						<Button onClick={goToView('accounts')} startIcon={<Settings />} variant='contained' color='primary'>
							<FormattedMessage id='integrations.instagram.accounts.button.manage' />
						</Button>
					</Box>
				</Figure>
			</Box>;
  }
  if (_.isEmpty(assets)) {
    return <Box display='flex' justifyContent='center' width='100%' height='100%'>
				<Figure height={250}>
					<img src='/static/placeholders/empty_folder.svg' />
					<figcaption>
						<FormattedMessage id='integrations.instagram.media_view.empty' values={{
            assetsType
          }} />
					</figcaption>
					{assetsType === 'stories' && <StoryLimitationsList>
							<li>
								<FormattedMessage id='integrations.instagram.media_view.limitations.availability' />
							</li>
							<li>
								<FormattedMessage id='integrations.instagram.media_view.limitations.story_types' />
							</li>
						</StoryLimitationsList>}
				</Figure>
			</Box>;
  }
  return <InfiniteMasonry containerNode={containerNode} items={assets} isLoading={isLoading} loadMore={loadMore} render={MediaItemWithContext} />;
}
MediaAssetsTab.propTypes = {
  containerNode: PropTypes.any,
  assets: PropTypes.array.isRequired,
  assetsType: PropTypes.string.isRequired,
  selectedIds: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  loadMore: PropTypes.func.isRequired,
  goToView: PropTypes.func
};
function MediaItem({
  data: {
    media_type,
    media_url,
    thumbnail_url,
    like_count,
    comments_count,
    permalink
  },
  selectedIndex,
  onClick
}) {
  if (!['IMAGE', 'VIDEO'].includes(media_type)) {
    return null;
  }
  return <MediaAssetCard className={cx({
    selected: selectedIndex !== -1
  })} width='100%'>
			{media_type === 'VIDEO' ? <video preload='none' loop={true} onMouseOver={playVideo} onMouseOut={event => event.target.pause()} muted={true} src={media_url} poster={thumbnail_url} onClick={onClick} /> : <img src={media_url} onClick={onClick} />}
			<SocialMetrics likeCount={like_count} commentsCount={comments_count} />
			{selectedIndex !== -1 && <SelectedIndexLabel label={selectedIndex + 1} sx={{
      position: 'absolute',
      bottom: 2,
      right: 5
    }} />}
			{permalink && <Box sx={{
      position: 'absolute',
      top: 5,
      right: 5
    }}>
					<Tooltip title={<FormattedMessage id='integrations.instagram.media_view.button.open_link' />} disableInteractive>
						<MediaAssetIconButton href={permalink} target='_blank' size='small'>
							<OpenInNew fontSize='small' />
						</MediaAssetIconButton>
					</Tooltip>
				</Box>}
		</MediaAssetCard>;
}
function SelectedCount({
  count,
  clearSelection,
  confirmSelection
}) {
  if (count === 0) {
    return null;
  }
  return <SelectedCountContainer>
			<ValidateButton onClick={confirmSelection}>
				<Box mr={1}>
					<FormattedMessage id='button.import_selection' />
				</Box>
				<SelectedIndexLabel label={count} />
			</ValidateButton>

			<Tooltip title={<FormattedMessage id='button.unselect_all' />}>
				<IconButton onClick={clearSelection} size='large'>
					<SvgIcon>
						<TrashIcon />
					</SvgIcon>
				</IconButton>
			</Tooltip>
		</SelectedCountContainer>;
}
SelectedCount.propTypes = {
  count: PropTypes.number,
  clearSelection: PropTypes.func,
  confirmSelection: PropTypes.func
};
function SocialMetrics({
  likeCount,
  commentsCount
}) {
  if (!likeCount && !commentsCount) {
    return null;
  }
  return <Box sx={{
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    px: 2,
    py: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    display: 'flex',
    gap: 2,
    fontSize: 14
  }}>
			{typeof likeCount !== 'undefined' && likeCount > 0 && <Box sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 1
    }}>
					<CustomIcon name='InstagramLikeIcon' />
					<div>{likeCount}</div>
				</Box>}

			{typeof commentsCount !== 'undefined' && commentsCount > 0 && <Box sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 1
    }}>
					<CustomIcon name='InstagramCommentIcon' />
					<div>{commentsCount}</div>
				</Box>}
		</Box>;
}

// Helpers
function loadInitialState(assetsTypes) {
  return _.reduce(assetsTypes, (state, assetsType) => ({
    ...state,
    [assetsType]: {
      assets: [],
      paging: undefined,
      error: undefined
    }
  }), {
    tab: assetsTypes[0],
    isLoading: true,
    selectedIds: []
  });
}